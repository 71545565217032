'use strict'

const service = config.VUE_APP_service_licenze || ''
const sellogicKey = config.sellogic_key || ''

async function updateLicenzaDispositivo(apikey, licenzaDispositivo) {
  try {
    const data = {
      apikey,
      licenzaDispositivo
    }
    const response = await fetch(`${service}/devices`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function deleteLicenzaDispositivo(apikey, codiceLicenzaDispositivo) {
  try {
    const data = {
      apikey,
      codiceLicenzaDispositivo
    }
    const response = await fetch(`${service}/devices`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function elencoLicenzeDispositivo(apikey) {
  try {
    const response = await fetch(`${service}/devices/${apikey}`)
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function getStatoDispositivo(apikey, code) {
  try {
    const response = await fetch(`${service}/devices/${apikey}/${code}`)
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function eliminaAssociazioneLicenza(apikey, licenza, udid, codiceAssociazione) {
  try {
    const data = {
      apikey,
      licenza,
      udid,
      codiceAssociazione
    }
    const response = await fetch(`${service}/devices/unmap`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-sellogic-key': sellogicKey
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

export default {
  updateLicenzaDispositivo,
  deleteLicenzaDispositivo,
  elencoLicenzeDispositivo,
  getStatoDispositivo,
  eliminaAssociazioneLicenza
}
