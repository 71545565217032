
import moment from 'moment'

const statiLicenza = [
  { codice: 'attiva', descrizione: 'Attiva' },
  { codice: 'inattiva', descrizione: 'Inattiva' },
  { codice: 'scaduta', descrizione: 'Scaduta' },
  { codice: 'demo', descrizione: 'Demo' }
]

export const getStatoLicenza = function (attiva) {
  var stato
  switch (attiva) {
    case 1:
      stato = statiLicenza.find(x => x.codice === 'attiva')        
      break
    case 2:
      stato = statiLicenza.find(x => x.codice === 'scaduta')
      break
    case 0:
      stato = statiLicenza.find(x => x.codice === 'inattiva')
      break
    default:
      stato = statiLicenza.find(x => x.codice === 'inattiva')
      break
  }
  return stato
}

const mergeIntervals = function (intervals) {
  const sorted = intervals
    .map(interval => ({
      start: new Date(interval.start),
      end: new Date(interval.end),
      allowedDays: interval.allowedDays
    }))
    .sort((a, b) => a.start - b.start);

  const merged = [];
  for (const interval of sorted) {
    if (
      merged.length === 0 ||
      interval.start > merged[merged.length - 1].end
    ) {
      merged.push(interval);
    } else {
      merged[merged.length - 1].end = new Date(
        Math.max(merged[merged.length - 1].end.getTime(), interval.end.getTime())
      );
    }
  }
  return merged;
}

export const calculateTotalDays = function(intervals) {
  const mergedIntervals = mergeIntervals(intervals);
  let totalDays = 0;
  for (const { start, end } of mergedIntervals) {
    const days = (end - start) / (1000 * 60 * 60 * 24);
    totalDays += (days + 1);
  }
  return totalDays;
}

/**
 * Calcola il numero totale di giorni "consentiti" coperti dagli intervalli,
 * contando solo i giorni la cui settimana è inclusa in allowedDays.
 * @param {Array<{start: Date, end: Date}>} intervals - Array di intervalli (start ed end come Date)
 * @param {number[]} allowedDays - Array di numeri (0 = Domenica, …, 6 = Sabato) dei giorni da considerare
 * @returns {number} - Numero totale di giorni consentiti
 */
export const calculateTotalAllowedDays = function (intervals) {
  let totalDays = 0;
  const mergedIntervals = mergeIntervals(intervals);
  for (const { start, end, allowedDays } of mergedIntervals) {
    let current = new Date(start);
    while (current <= end) {
      if (!allowedDays || allowedDays.includes(current.getDay())) {
        totalDays++;
      }
      current.setDate(current.getDate() + 1);
    }
  }
  return totalDays;
}

/**
 * Verifica se una data cade all'interno di uno degli intervalli e in un giorno consentito.
 * @param {string | Date} date - La data da controllare (può essere una stringa o un oggetto Date)
 * @param {Array<{start: Date, end: Date}>} intervals - Array di intervalli (con proprietà start ed end di tipo Date)
 * @param {number[]} allowedDays - Array di numeri che rappresentano i giorni consentiti (0 = Domenica, ..., 6 = Sabato)
 * @returns {integer} - 2 se la data è successiva agli intervalli, 1 se la data cade in un intervallo e il giorno è consentito, 0 altrimenti
 */

export const isDateInIntervalsWithAllowedDays = function (date, intervals) {
  const d = date instanceof Date ? date : new Date(date);
  let expired = true
  for (const interval of intervals) {
    if (moment(d).isBefore(moment(interval.end).endOf('day'))) {
      expired = false
    }
    if (moment(d).isBetween(moment(interval.start).startOf('day'), moment(interval.end).endOf('day')) && (!interval.allowedDays || interval.allowedDays.includes(d.getDay()))) {
      return 1;
    }
  }
  return expired ? 2 : 0;
}

export const intervalsLimitsAllowedDays  = function (intervals) {
  const mergedIntervals = mergeIntervals(intervals);
  var startMin, endMax, ad
  for (const { start, end, allowedDays } of mergedIntervals) {
    ad = allowedDays
    if (!startMin || start < startMin) {
      startMin = start
    }
    if (!endMax || end > endMax) {
      endMax = end
    }    
  }
  return { start: startMin, end: endMax, allowedDays: ad }
}

export const isDateInIntervals = function(date, intervals) {
  const d = date instanceof Date ? date : new Date(date);
  for (const interval of intervals) {
    if (moment(d).isBetween(moment(interval.start).startOf('day'), moment(interval.end).endOf('day'))) {
      return true;
    }
  }
  return false;
}

export const intervalsLimits = function (intervals) {
  const mergedIntervals = mergeIntervals(intervals);
  var startMin, endMax
  for (const { start, end } of mergedIntervals) {
    if (!startMin || start < startMin) {
      startMin = start
    }
    if (!endMax || end > endMax) {
      endMax = end
    }    
  }
  return { start: startMin, end: endMax }
}
