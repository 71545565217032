<template>
  <div>
    <v-sheet  v-if="mostraLinkAssociazione" class="pa-12 mt-3" color="grey lighten-4">
      <v-container>
        <v-row>
          <v-col md="3">
            <qrcode-vue :value="linkAssociazioneDispositivo" :size="sizeQr" level="M" />  
          </v-col>
          <v-col md="8">
            <h3>Leggi il QRCode con il dispositivo che vuoi associare alla licenza</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="secondary" class="mb-2" @click="onAnnullaAssociaDispositivo">Annulla</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-data-table
      v-model="selected"
      color="blue lighten-4"
      item-key="codiceAssociazione"
      :headers="headers"
      :items="associazioniSorted"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 50, 100]
      }"
    >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Associazione dispositivi</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="mostraAssocia" color="secondary" class="mb-2" :disabled="mostraLinkAssociazione" @click="onAssociaDispositivo">Associa dispositivo</v-btn>
        <v-btn v-if="mostraDisassocia" color="warning" class="mb-2" @click="onCancellaAssociazione">Cancella associazione</v-btn>
        <h3 v-if="mostraScaduta" class="mb-2 red--text" readonly>Licenza scaduta. Nessuna operazione possibile !</h3>

        <v-dialog v-model="dialogCancellaAssociazione" max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Confermi cancellazione associazione dispositivo?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="upmapConfirm">SI</v-btn>
              <v-btn color="blue darken-1" text @click="unmapClose">NO</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <dialog-errore :show="dialogErrore" title="Errore associazione dispositivo" />
      </v-toolbar>
    </template>    
    <template v-slot:[`item.istanteOn`]="{ item }">
      {{ item.istanteOn | dateTime }}
    </template>
    <template v-slot:[`item.istanteOff`]="{ item }">
      {{ item.istanteOff | dateTime }}
    </template>
    </v-data-table>
  </div>
</template>

<script>
  import DialogErrore from '@/components/dialogErrore'
  import QrcodeVue from '@/plugins/qrcode/qrcode.vue.js'
  const crypto = require('crypto');
  //  "note": "su dettagli licenza: dispositivo associato, date in /out, operatori associati"

  export default {
    components: {
      DialogErrore,
      QrcodeVue
    },
    props: {
      codice: String,
      apikey: String,
      associazioni: Array,
      statoLicenza: Object,
      statoAssociazione: Object,
      refresh: Number
    },
    data: () => ({
        selected: [],
        loading: false,
        options: {},
        headers: [
          { text: 'Codice', value: 'codiceAssociazione', align: 'start'},
          { text: 'UDID dispositivo', value: 'udid' },
          { text: 'OS', value: 'info.os' },
          { text: 'App', value: 'info.appVersion' },
          { text: 'Attivo', value: 'attivo' },
          { text: 'Istante On', value: 'istanteOn' },
          { text: 'Istante Off', value: 'istanteOff' }
        ],      
        filtri: null,
        causali: [],
        dialogCancellaAssociazione: false,
        dialogErrore: false,
        sizeQr: 200,
        mostraLinkAssociazione: false,
        rndSalt: ''
    }),
    watch: {
      async refresh() {
        this.mostraLinkAssociazione = false
      }
    },
    computed: {
      mostraAssocia() {
        return ['libera'].includes(this.statoAssociazione.codice) && ['attiva', 'inattiva', 'demo'].includes(this.statoLicenza.codice)
      },
      mostraDisassocia() {
        return ['associata'].includes(this.statoAssociazione.codice) && ['attiva', 'inattiva', 'demo'].includes(this.statoLicenza.codice)
      },
      mostraScaduta() {
        return ['scaduta'].includes(this.statoLicenza.codice)
      },
      linkAssociazioneDispositivo() {
        return `https://impronto.ubi-co.com?code=${this.codice}&key=${this.apikey}&salt=${this.rndSalt}`
      },
      associazioniSorted() {
        return _.sortBy(this.associazioni, 'IstanteOn').reverse()
      }
    },
    async mounted() {
    },
    methods: {
      onAssociaDispositivo() {
        this.rndSalt = crypto.randomBytes(16).toString('hex')
        // TODO: Verificare se salvare per la verifica della chiamata ...
        this.mostraLinkAssociazione = true
        // Avviare polling per verifica associazione
        this.$emit('polling', true, this.codice)

      },
      onAnnullaAssociaDispositivo() {
        this.$emit('polling', false, this.codice)
        this.mostraLinkAssociazione = false
      },
      onCancellaAssociazione() {
        this.dialogCancellaAssociazione = true
      },
      upmapConfirm() {
        this.$emit('unmap', this.codice)
        this.dialogCancellaAssociazione = false
      },
      unmapClose() {
        this.dialogCancellaAssociazione = false
      }
    }
  }
</script>
