<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="licenzeDispositivo"
      item-key="codice"
      sort-by="tipo"
      class="elevation-1"
      dense
      :loading="loading"
      hide-default-footer
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark small class="mb-2" v-bind="attrs" v-on="on" dense>
                Nuova Licenza dispositivo
              </v-btn>
              <v-spacer></v-spacer>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedItem.tipo" label="Tipo licenza" :items="tipiLicenza" dense flat outlined item-text="descrizione" item-value="codice" :hint="editedItem.hint" persistent-hint @change="changeTipoLicenza"></v-select>
                    </v-col>
                    <v-col v-if="editedItem.tipo === 'periodica'">
                      <v-btn @click="aggiungiPeriodo()">Aggiungi periodo</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-if="['temporanea', 'aConsumo'].includes(editedItem.tipo)"  cols="12" sm="4" md="3">
                      <v-text-field v-model="editedItem.numeroGiorni" class="mt-0" outlined label="Totale giorni richiesti" hide-details dense type="number" @change="changeNumeroGiorni"></v-text-field>
                    </v-col>
                    <v-col v-else cols="12" sm="4" md="2">
                      <v-text-field v-model="editedItem.totaleGiorni" class="mt-0" outlined label="Totale giorni" readonly hide-details dense></v-text-field>
                    </v-col>
                  </v-row>
                  <template v-if="editedItem.tipo === 'periodica'">
                    <div v-for="(p, idx) in editedItem.periodi" :key="idx">
                      <v-row dense>
                        <v-col cols="12" sm="6" md="4" >
                          <v-menu
                            v-model="menuStart[idx]"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="dateFormat(p.start)"
                                label="Dalla data"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                hide-details
                                @click:clear="clearStartDate(p)"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="p.start" no-title scrollable @input="changeStartDate(idx)"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" >
                          <v-menu
                            v-model="menuEnd[idx]"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="dateFormat(p.end)"
                                label="Alla data"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                hide-details
                                @click:clear="clearEndDate(p)"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="p.end" :min="p.start" no-title scrollable @input="changeEndDate(idx)"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col>
                          <v-icon class="mt-2" @click="cancellaPeriodo(idx)">mdi-delete-outline</v-icon>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-5">
                        <v-col></v-col>
                        <v-col cols="1"><v-checkbox v-model="p.allowedDays" hide-details dense label="L" :value="1" @change="ricalcolaTotaleGiorni"></v-checkbox></v-col>
                        <v-col cols="1"><v-checkbox v-model="p.allowedDays" hide-details dense label="M" :value="2" @change="ricalcolaTotaleGiorni"></v-checkbox></v-col>
                        <v-col cols="1"><v-checkbox v-model="p.allowedDays" hide-details dense label="M" :value="3" @change="ricalcolaTotaleGiorni"></v-checkbox></v-col>
                        <v-col cols="1"><v-checkbox v-model="p.allowedDays" hide-details dense label="G" :value="4" @change="ricalcolaTotaleGiorni"></v-checkbox></v-col>
                        <v-col cols="1"><v-checkbox v-model="p.allowedDays" hide-details dense label="V" :value="5" @change="ricalcolaTotaleGiorni"></v-checkbox></v-col>
                        <v-col cols="1"><v-checkbox v-model="p.allowedDays" hide-details dense label="S" :value="6" @change="ricalcolaTotaleGiorni"></v-checkbox></v-col>
                        <v-col cols="1"><v-checkbox v-model="p.allowedDays" hide-details dense label="D" :value="0" @change="ricalcolaTotaleGiorni"></v-checkbox></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>                        
                      </v-row>
                    </div>
                  </template>                                               
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Annulla</v-btn>
                <v-btn color="blue darken-1" text @click="save">Salva</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="text-h5">Sei sicuro di voler cancellare questa licenza ?</v-card-title>
              <v-card-subtitle class="mt-3 red--text text-center">Attenzione, eventuali periodi di utilizzo saranno comunque fatturati</v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Annulla</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>       
        </v-toolbar>
      </template>
      <template v-slot:[`item.statoAssociazione`]="{ item }">
        {{ item.statoAssociazione.descrizione }}
      </template>
      <template v-slot:[`item.statoLicenza`]="{ item }">
        <span :class="verificaStatoLicenza(item.periodi) === 'Scaduta' ? 'red--text' : ''">{{ verificaStatoLicenza(item.periodi) }}</span>
      </template>
      <template v-slot:[`item.validaDa`]="{ item }">
        {{ item.validaDa | date }}
      </template>
      <template v-slot:[`item.validaA`]="{ item }">
        {{ item.validaA | date }}
      </template>    
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)" title="Modifica la licenza">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)" title="Cancella la licenza">mdi-delete</v-icon>
        <v-icon small @click="duplicateItem(item)" title="Duplica la licenza">mdi-content-duplicate</v-icon>
      </template>
      <template v-slot:no-data>
        Nessuna licenza definita
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="licenzaSelected">
          <v-tabs dense v-model="tab[item.codice]">
            <v-tab key="associazioni" class="licenzaSelected">Associazioni<!-- <v-btn icon @click="trigRefreshLicenze++"><v-icon>mdi-refresh</v-icon></v-btn> --></v-tab>
            <v-tab key="rinnovi" class="licenzaSelected">Rinnovi<!-- <v-btn icon @click="trigRefreshLicenze++"><v-icon>mdi-refresh</v-icon></v-btn> --></v-tab>
            <v-tab key="uso" class="licenzaSelected">Uso<!-- <v-btn icon @click="trigRefreshLicenze++"><v-icon>mdi-refresh</v-icon></v-btn> --></v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab[item.codice]">
            <v-tab-item key="associazioni">
              <associazioni :codice="item.codice" :apikey="apikey" :associazioni="item.associazioni" :statoLicenza="item.statoLicenza" :statoAssociazione="item.statoAssociazione" :refresh="trigRefreshLicenze" @polling="onPolling" @unmap="onCancellaAssociazione"/>
            </v-tab-item>
            <v-tab-item key="rinnovi">
              <rinnovi :rinnovi="item.rinnovi" :refresh="trigRefreshLicenze"/>
            </v-tab-item>
            <v-tab-item key="uso">
              <uso :uso="item.uso" :refresh="trigRefreshLicenze"/>
            </v-tab-item>
          </v-tabs-items>
        </td>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :color="snackbarColor" bottom><h4>{{ snackbarText }}</h4></v-snackbar> 
  </div>
</template>

<script>
  /* eslint-disable */
  import moment from 'moment'
  import { validazioni } from '@/mixins/validazioni.js'
  import { getStatoLicenza, calculateTotalAllowedDays, isDateInIntervalsWithAllowedDays, intervalsLimitsAllowedDays } from '@/helpers/periodi.js'
  import dispositivi from '@/services/dispositiviService.js'
  
  import Associazioni from './elementi/associazioni'
  import Rinnovi from './elementi/rinnovi'
  import Uso from './elementi/uso'

  const crypto = require('crypto');
  
  export default {
    components: {
      Associazioni,
      Rinnovi,
      Uso
    },
    mixins: [
      validazioni
    ],
    props: {
      apikey: String,
      trigger: Number
    },
    data: () => ({
      tab: {},
      loading: false,
      dialog: false,
      dialogDelete: false,
      licenzeDispositivo: [],
      headers: [
        {
          text: 'Codice',
          align: 'start',
          sortable: false,
          value: 'codice',
        },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Stato', value: 'statoLicenza' },
        { text: 'Associazione', value: 'statoAssociazione' },
        { text: 'Dal', value: 'validaDa' },
        { text: 'Al', value: 'validaA' },
        { text: 'Giorni', value: 'totaleGiorni', align: 'end' },
        { text: 'Dispositivo', value: 'dispositivo' },
        { text: 'Operatore', value: 'operatore' },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' }  // su expand uso ed associazioni successive
      ],
      updateTrigger: 0,
      editedIndex: -1,
      // TODO: RIVEDERE IL CARICAMENTO DEI MODULI E LA MODALITA' DI CREAZIONE DELLA DIALOG DI INSERIMENTO LICENZA--> METTERE COMPONENTI
      editedItem: {
        codice: '',
        tipo: '',
        statoLicenza: '',
        statoAssociazione: '',
        validaDa: '',
        validaA: '',
        dispositivo: '',
        operatore: '',
        periodi: []
      },
      defaultItem: {
        codice: '',
        tipo: '',
        statoLicenza: '',
        statoAssociazione: '',
        validaDa: '',
        validaA: '',
        dispositivo: '',
        operatore: '',
        periodi: []
      },
      expanded: [],
      singleExpand: false,
      tipiLicenza: [
        { codice: 'standard', descrizione: 'Standard', descrizioneLicenza: 'Licenza annuale, durata 365 giorni dall\'attivazione' },
        { codice: 'periodica', descrizione: 'Periodica', descrizioneLicenza: 'Licenza valida per i periodi impostati' },
        { codice: 'temporanea', descrizione: 'Temporanea', descrizioneLicenza: 'Licenza valida dall\'attivazione per i giorni richiesti' },
        { codice: 'aConsumo', descrizione: 'A consumo', descrizioneLicenza: 'Licenza ricaricabile a consumo. Vengono conteggiati gli effettivi giorni di utilizzo' },
      ],
      menuStart: [],
      menuEnd: [],
      tipoLicenza: '',
/*       statiLicenza: [
        { codice: 'attiva', descrizione: 'Attiva' },
        { codice: 'inattiva', descrizione: 'Inattiva' },
        { codice: 'scaduta', descrizione: 'Scaduta' },
        { codice: 'demo', descrizione: 'Demo' }
      ], */
      statiAssociazione: [
        { codice: 'associata', descrizione: 'Associata' },
        { codice: 'libera', descrizione: 'Libera' }
      ],
      giorniSelezionati: [],
      trigRefreshLicenze: 1,
      pollingTimer: null,
      snackbar: false,
      snackbarText: '',
      snackbarColor: ''
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuova licenza dispositivo' : 'Modifica licenza dispositivo'
      },
      rulesValidationRequired() {
        let rl = []
        rl.push(this.rules.required)
        return rl
      },
      rulesValidationEmail() {
        let rl = []
        rl.push(this.rules.required)
        rl.push(this.rules.email)
        return rl
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    async mounted() {
      // this.ricalcolaTotaleGiorni()
      await this.getDataFromApi()
    },
    methods: {
      verificaStatoLicenza(periodi) {
        const attiva = isDateInIntervalsWithAllowedDays(new Date(), periodi)
        return getStatoLicenza(attiva).descrizione        
      },
      ricalcolaTotaleGiorni() {
        const periodi = this.editedItem.periodi.filter(x => moment(x.start).isValid() && moment(x.end).isValid())
        let delta = calculateTotalAllowedDays(periodi)
        this.editedItem.totaleGiorni = this.editedItem.tipo === 'aConsumo' ? parseInt(this.editedItem.numeroGiorni) : delta
      },
      changeStartDate(idx) {
        this.menuStart[idx] = false
        this.editedItem.periodi[idx].end = this.editedItem.periodi[idx].start 
        this.ricalcolaTotaleGiorni()
      },
      changeEndDate(idx) {
        this.menuEnd[idx] = false
        this.ricalcolaTotaleGiorni()
      },
      clearStartDate(p) {
        p.start = null
        this.ricalcolaTotaleGiorni()
      },
      clearEndDate(p) {
        p.end = null
        this.ricalcolaTotaleGiorni()
      },      
      cancellaPeriodo(idx) {
        this.editedItem.periodi.splice(idx, 1)
        this.ricalcolaTotaleGiorni()
      },
      svuotaPeriodi() {
        if (this.editedItem.periodi) {
          while(this.editedItem.periodi.length > 0) {
            this.editedItem.periodi.pop()
          }
        }
      },
      changeNumeroGiorni() {
        if (this.editedItem.tipo === 'temporanea') {
          this.svuotaPeriodi()
          const periodo = {
            start: moment().format('YYYY-MM-DD'),
            end: moment().add(parseInt(this.editedItem.numeroGiorni || '0') - 1, 'days').format('YYYY-MM-DD')
          }
          this.editedItem.periodi.push(periodo)
          this.ricalcolaTotaleGiorni()
        } else if (this.editedItem.tipo === 'aConsumo') {
          this.ricalcolaTotaleGiorni()
        }
      },      
      changeTipoLicenza() {
        const tipo = this.tipiLicenza.find(x => x.codice === this.editedItem.tipo)
        this.editedItem.hint = tipo.descrizioneLicenza
        this.editedItem.totaleGiorni = 0
        this.editedItem.numeroGiorni = ''
        this.svuotaPeriodi()
        if (this.editedItem.tipo === 'standard') {
          const periodo = {
            start: moment().format('YYYY-MM-DD'),
            end: moment().add(1, 'years').add(-1, 'days').format('YYYY-MM-DD')
          }
          this.editedItem.periodi.push(periodo)
          this.ricalcolaTotaleGiorni()
        }
      },
      dateFormat (date) {
        return this.formatDate(date)
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      aggiungiPeriodo() {
        this.editedItem.periodi = this.editedItem.periodi || []
        this.editedItem.periodi.push({
          start: null,
          end: null,
          allowedDays: [0, 1, 2, 3, 4, 5, 6]
        })
      },
      editItem (item) {
        this.editedIndex = this.licenzeDispositivo.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      duplicateItem(item) {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, item)
        this.editedItem._id = ''
        delete this.editedItem._rev
        this.save()
      },
      deleteItem (item) {
        this.editedIndex = this.licenzeDispositivo.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.$emit('delete-licenza', this.apikey, this.editedItem)
        this.licenzeDispositivo.splice(this.editedIndex, 1)
        this.closeDelete()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
/*       getStatoLicenza(attiva) {
        var stato
        switch (attiva) {
          case 1:
            stato = this.statiLicenza.find(x => x.codice === 'attiva')        
            break
          case 2:
            stato = this.statiLicenza.find(x => x.codice === 'scaduta')
            break
          case 0:
            stato = this.statiLicenza.find(x => x.codice === 'inattiva')
            break
          default:
            stato = this.statiLicenza.find(x => x.codice === 'inattiva')
            break
        }
        return stato
      }, */
      save () {
        const limiti = intervalsLimitsAllowedDays(this.editedItem.periodi)
        this.editedItem.validaDa = limiti.start
        this.editedItem.validaA = limiti.end
        const attiva = isDateInIntervalsWithAllowedDays(new Date(), this.editedItem.periodi)
        this.editedItem.statoLicenza = getStatoLicenza(attiva)        
        if (this.editedIndex > -1) {
          Object.assign(this.licenzeDispositivo[this.editedIndex], this.editedItem)
          this.$emit('update-licenza', this.apikey, this.editedItem)
        } else {
          this.editedItem.codice = crypto.randomBytes(16).toString('hex')
          this.editedItem.statoAssociazione = this.statiAssociazione.find(x => x.codice === 'libera')
          this.$emit('update-licenza', this.apikey, this.editedItem)
          this.licenzeDispositivo.push(this.editedItem)
        }
        this.close()
      },
      async getDataFromApi () {
        try {
          this.loading = true
          let licenzeRaw = await dispositivi.elencoLicenzeDispositivo(this.apikey)
          this.licenzeDispositivo = licenzeRaw
        } catch(err) {
          this.licenzeDispositivo = []
        } finally {
          this.loading = false
        }
      },
      async onPolling(state, code) {
        // TODO: Gestire con eventi
        if (state) {
          this.pollingTimer = setInterval(async () => {
            const licenza = await dispositivi.getStatoDispositivo(this.apikey, code)
            if (licenza.statoAssociazione.codice === 'associata') {
              clearInterval(this.pollingTimer)
              this.pollingTimer = null
              await this.getDataFromApi()
              this.trigRefreshLicenze++
              this.snackbarText = 'Dispositivo associato alla licenza'
              this.snackbarColor = 'success'
              this.snackbar = true              
            }
          }, 1000)
        } else {
          clearInterval(this.pollingTimer)
          this.pollingTimer = null
          this.trigRefreshLicenze++
        }
      },
      async onCancellaAssociazione(code) {
        const licenza = this.licenzeDispositivo.find(x => x.codice === code)
        const associata = licenza.associazioni.find(x => x.attivo && !x.istanteOff)
        await dispositivi.eliminaAssociazioneLicenza(this.apikey, code, associata.udid, associata.codiceAssociazione)
        await this.getDataFromApi()
        this.snackbarText = 'Associazione dispositivo eliminata'
        this.snackbarColor = 'info'
        this.snackbar = true
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.licenzaSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #119432 !important;
  }
</style>
