<template>
  <v-data-table
      v-model="selected"
      color="blue lighten-4"
      item-key="codiceRinnovo"
      :headers="headers"
      :items="rinnovi"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 50, 100]
      }"
    >
    <template v-slot:[`item.data`]="{ item }">
      {{ item.data | dateTime }}
    </template>
    </v-data-table>
</template>

<script>

  //  "note": "su dettagli licenza: dispositivo associato, date in /out, operatori associati"

  /* eslint-disable */
  export default {
    props: {
      rinnovi: Array,
      refresh: Number
    },
    data: () => ({
        selected: [],
        loading: false,
        options: {},
        headers: [
          { text: 'Codice', value: 'codiceRinnovo', align: 'start'},
          { text: 'Data', value: 'data' },
          { text: 'Importo', value: 'importo' },
          { text: 'Stato pag.', value: 'statoPagamento' },
          { text: 'Tipo pag.', value: 'tipoPagamento' },
          { text: 'Info pag.', value: 'infoPagamento' },
          { text: 'Fattura', value: 'fattura' },
        ],      
        filtri: null,
        causali: []
    }),
    watch: {
      async refresh() {
      }
    },
    async mounted() {
    },
    methods: {
    }
  }
</script>
