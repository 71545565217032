<template>
  <div>
    <v-container fluid dense>
      <v-stepper v-model="stepper" @change="changeStep">
      <v-stepper-header>
        <v-stepper-step v-for="(gruppo, idx) in gruppi" :key="idx" :complete="stepper === idx + 1" :step="idx + 1" editable>{{ gruppo }}</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-progress-linear :indeterminate="loading" :active="loading"  class="mb-0"></v-progress-linear>
<!--         <v-btn icon class="ml-3" title="Refresh impostazioni">
            <v-icon @click="refreshImpostazioni">mdi-refresh</v-icon>
          </v-btn> -->
        <v-text-field class="stepperSearch" label="Cerca" dense v-model="textSearch" append-icon="mdi-magnify" clearable outlined filled background-color="white">
          <template v-slot:prepend>
            <v-icon @click="refreshImpostazioni" title="Refresh impostazioni">mdi-refresh</v-icon>
          </template>
        </v-text-field>
        <v-stepper-content v-for="(gruppo, idx) in gruppi" :key="idx" :step="idx + 1">
          <v-row>
            <v-col cols="12" v-for="(categoria, idxC) of elencoCategorie(gruppo)" :key="idxC">
              <v-sheet color="green lighten-5" width="100%" class="pt-2 pb-2">
                <v-row class="ml-1">
                  <v-col cols="12">
                    <h4>{{ categoria }}</h4>
                  </v-col>
                </v-row>
                <v-row dense class="pl-2 pr-2 pb-2">
                  <v-col v-for="(item, idxD) of elencoDati(gruppo, categoria)" :key="idxD" cols="3">
                    <v-card class="mx-auto" :disabled="loading || saving">
                      <v-list-item three-line dense>
                        <v-list-item-icon>
                          <v-checkbox v-model="item.checked" :disabled="item.disabled" dense hide-details title="Include / esclude il blocco dalle impostazioni"></v-checkbox>
                        </v-list-item-icon>                    
                        <v-list-item-content>
                          <v-list-item-title>{{ item.titolo }}</v-list-item-title>
                          <v-list-item-subtitle>{{ item.note }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row class="d-flex justify-start">
                            <v-col cols="5">
                              <v-btn icon title="Modifica i valori" v-show="item.daPersonalizzare">
                                <v-icon color="grey lighten-1" @click="modificaValori(item, idxD)">mdi-pencil</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="5">
                              <v-btn icon title="Ulteriori informazioni" v-show="item.info">
                                <v-icon color="grey lighten-1" @click="mostraInfo(item)">mdi-information</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>                       
                        </v-list-item-action>                 
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>   
      </v-stepper-content>
<!--       <v-card class="mb-12" color="text-center mb-4" height="100px">
        <v-card-subtitle class="text-h5">Nessuna impostazione trovata per il filtro specificato</v-card-subtitle>
      </v-card> -->
      <v-btn color="success" class="salvaImpostazioni" :loading="saving"  @click="salvaImpostazioni">Salva</v-btn>
      </v-stepper-items>
    </v-stepper>
    </v-container>
    <v-dialog v-model="dialogInfo" max-width="500" :persistent="true">
      <v-card>
        <v-card-title class="text-h5">{{dialogTitle}}</v-card-title>
        <!-- eslint-disable-next-line-->
        <v-card-text v-html="markdownInfo"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="infoConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="1000" :persistent="true">
      <v-card>
        <v-card-title class="text-h5">
          {{dialogEditTitle}}
          <v-spacer></v-spacer>
          <v-btn icon title="Ulteriori informazioni" v-show="itemEdit.info">
            <v-icon color="grey lighten-1" @click="mostraInfo(itemEdit)">mdi-information</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <v-row dense>
            <v-col v-for="(ed, idx) in editInfo" class="pl-3 pr-3" cols="12" sm="12" :md="['group', 'editor', 'list'].includes(ed.type) ? 12 : 6" :key="idx">
              <span class="grey--text text--lighten-2">{{ ed.type }}</span> <!-- Togliere solo debug -->
              <v-text-field v-if="ed.type==='text'" :label="ed.label" v-model="ed.value" :hint="ed.hint" :persistent-hint="ed.persistent" :required="ed.required" :rules="ed.rules"></v-text-field>
              <v-switch v-else-if="ed.type==='boolean'" v-model="ed.value" :label="ed.label" :hint="ed.hint" :persistent-hint="ed.persistent" inset></v-switch>
              <template v-else-if="ed.type==='color'">
                <div>{{ ed.label }}</div>
                <v-color-picker mode="hexa" v-model="ed.value" hide-canvas hide-inputs></v-color-picker>
                <div class="text-caption mb-3">{{ ed.hint }}</div>
              </template>
              <v-text-field v-else-if="ed.type==='number'" :label="ed.label" v-model="ed.value" :hint="ed.hint" :persistent-hint="ed.persistent" :required="ed.required" :rules="ed.rules"></v-text-field>
              <v-select v-else-if="ed.type==='select'" :label="ed.label" v-model="ed.value" :hint="ed.hint" :persistent-hint="ed.persistent" :items="sourceSorted(ed.source, ed.sourceSortedBy)" item-text="descrizione" item-value="codice"></v-select>
              <v-select v-else-if="ed.type==='combo'" :label="ed.label" v-model="ed.value" :hint="ed.hint" :persistent-hint="ed.persistent" :items="sourceSorted(ed.source, ed.sourceSortedBy)" item-text="descrizione" item-value="codice" multiple chips></v-select>
              <template v-else-if="ed.type==='editor'">
                <div>{{ ed.label }}</div>
                <v-json-editor v-model="ed.value" :options="optionsWeb" :plus="true" history @error="onErrorWeb" style="height: 95%;" ref="jsonEditor"/>
                <!-- <small>Per ricaricare i valori di default dell'impostazione (se modificata per il cliente) è sufficiente rimuovere il parametro e ricaricare la lista</small> -->
              </template>
              <template v-else-if="ed.type==='date'">
                <v-menu v-model="menuData[idx]" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="formatDate(ed.value)" label="Dalla data" prepend-inner-icon="mdi-calendar" readonly outlined dense v-bind="attrs" v-on="on" clearable hide-details @click:clear="filtro.start = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="ed.value" no-title scrollable @input="menuData[idx] = false"></v-date-picker>
                </v-menu>
              </template>
              <template v-else-if="ed.type==='list'">
                <v-card>
                  <v-data-table :headers="ed.headers" :items="ed.items" :items-per-page="10" class="elevation-1" dense>
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>
                        <div>{{ ed.label }}</div>
                        <div class="text-caption">{{ ed.hint }}</div>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-dialog  v-model="dialogTable[idx]" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" dense>Nuovo valore</v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">{{ ed.label }}</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" v-for="(itm, idt) in ed.headers.filter(x => x.editable)" :key="idt">
                                  <v-switch v-if="itm.edit === 'boolean'" v-model="editedItem[itm.value]" :label="itm.text"></v-switch>
                                  <v-text-field v-else-if="itm.edit==='number'" :label="itm.text" v-model="editedItem[itm.value]" :hint="itm.hint" :persistent-hint="itm.persistent" :required="itm.required" :rules="itm.rules"></v-text-field>
                                  <v-select v-else-if="itm.edit==='select'" :label="itm.text" v-model="editedItem[itm.value]" :hint="itm.hint" :persistent-hint="itm.persistent" :items="sourceSorted(itm.source, itm.sourceSortedBy)" item-text="descrizione" item-value="codice"></v-select>
                                  <v-select v-else-if="itm.edit==='combo'" :label="itm.label" v-model="editedItem[itm.value]" :hint="itm.hint" :persistent-hint="itm.persistent" :items="sourceSorted(itm.source, itm.sourceSortedBy)" item-text="descrizione" item-value="codice" multiple chips></v-select>
                                  <v-text-field v-else v-model="editedItem[itm.value]" :label="itm.text"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeTable(idx)">Annulla</v-btn>
                            <v-btn color="blue darken-1" text @click="saveTable(idx, ed.items)">Salva</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogDeleteTable[idx]" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5">Confermi la cancellazione ?</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialogDeleteTable[idx] = false">Annulla</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm(idx, ed.items)">OK</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editTableItem(item, idx, ed.items)" title="Modifica il valore">mdi-pencil</v-icon>
                    <v-icon small @click="deleteTableItem(item, idx, ed.items)" title="Cancella il valore">mdi-delete</v-icon>
                    <v-icon small @click="moveUpTableItem(item, ed.items)" class="ml-5" title="Sposta su">mdi-arrow-up-bold-outline</v-icon>
                    <v-icon small @click="moveDownTableItem(item, ed.items)" class="ml-1" title="Sposta giù">mdi-arrow-down-bold-outline</v-icon>
                  </template>
                  <template v-slot:no-data>
                    <div class="">Nessun dato trovato. Inserisci i tuoi valori</div>
                  </template>                  
                  </v-data-table>
                </v-card>
              </template>
              <template v-else-if="ed.type==='tabs'">
                <!-- 
                  Se la sorgente è una tabella e per ogni elemento devo specificare il tipo, inserisco una v-tabs con tabs scrollabili
                  Si istanzia come il gruppo ?
                  -->
                <v-tabs show-arrows v-model="tabModel">
                  <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                  <v-tab v-for="(item, idx) in ed.items" :key="idx" :href="'#tab-' + idx">Elemento {{ idx + 1 }}</v-tab>
                  <v-tabs-items v-model="tabModel">
                    <v-tab-item v-for="(item, idx) in ed.items" :key="idx" :value="`tab-${idx}`">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">{{ item.label }}</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row dense v-if="item.editInfo">
                              <v-col v-for="(ed, idx) in item.editInfo.filter(x => x.type !== 'hidden')" class="pl-3 pr-3" cols="12" sm="12" :md="['group', 'editor', 'list'].includes(ed.type) ? 12 : 6" :key="idx">
                                <v-text-field v-if="ed.type==='text'" :label="ed.label" v-model="ed.value" :hint="ed.hint" :persistent-hint="ed.persistent" :required="ed.required" :rules="ed.rules"></v-text-field>
                                <v-switch v-else-if="ed.type==='boolean'" v-model="ed.value" :label="ed.label" :hint="ed.hint" :persistent-hint="ed.persistent" inset></v-switch>
                                <template v-else-if="ed.type==='color'">
                                  <div>{{ ed.label }}</div>
                                  <v-color-picker mode="hexa" v-model="ed.value" hide-canvas hide-inputs></v-color-picker>
                                  <div class="text-caption mb-3">{{ ed.hint }}</div>
                                </template>
                                <v-text-field v-else-if="ed.type==='number'" :label="ed.label" v-model="ed.value" :hint="ed.hint" :persistent-hint="ed.persistent" :required="ed.required" :rules="ed.rules"></v-text-field>
                                <v-select v-else-if="ed.type==='select'" :label="ed.label" v-model="ed.value" :hint="ed.hint" :persistent-hint="ed.persistent" :items="sourceSorted(ed.source, ed.sourceSortedBy)" item-text="descrizione" item-value="codice"></v-select>
                                <v-select v-else-if="ed.type==='combo'" :label="ed.label" v-model="ed.value" :hint="ed.hint" :persistent-hint="ed.persistent" :items="sourceSorted(ed.source, ed.sourceSortedBy)" item-text="descrizione" item-value="codice" multiple chips></v-select>
                                <template v-else-if="ed.type==='editor'">
                                  <v-json-editor v-model="ed.value" :options="optionsWeb" :plus="true" history @error="onErrorWeb" style="height: 100%;" ref="jsonEditor"/>
                                  <!-- <small>Per ricaricare i valori di default dell'impostazione (se modificata per il cliente) è sufficiente rimuovere il parametro e ricaricare la lista</small> -->
                                </template>
                                <template v-else-if="ed.type==='date'">
                                  <v-menu v-model="menuData[idx]" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field :value="formatDate(ed.value)" label="Dalla data" prepend-inner-icon="mdi-calendar" readonly outlined dense v-bind="attrs" v-on="on" clearable hide-details @click:clear="filtro.start = null"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="ed.value" no-title scrollable @input="menuData[idx] = false"></v-date-picker>
                                  </v-menu>
                                </template>
                                <template v-else-if="ed.type==='group'">
                                  <v-subheader class="text-h6">{{ ed.label }}</v-subheader>
                                  <v-divider></v-divider>
                                </template>
                                <span v-else>
                                  Controllo non gestito
                                </span>
                              </v-col>	
                            </v-row>
                            
                          </v-container>
                        </v-card-text>
                        </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </template>
              <template v-else-if="ed.type==='group'">
                <v-subheader class="text-h6">{{ ed.label }}</v-subheader>
                <v-divider></v-divider>
              </template>
              <template v-else-if="ed.type==='hidden'"></template>
              <span v-else>
                Controllo non gestito
              </span>
            </v-col>
          </v-row>
        </v-container>   
        <v-card-actions class="mt-4">
          <span class="grey--text text--lighten-2">{{ editPosizione }}</span>
          <v-spacer></v-spacer>
          <v-btn color="orange darken-1" text @click="dialogEdit=false">Annulla</v-btn>
          <v-btn color="blue darken-1" text @click="editConfirm">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>       
  </div>
</template>
<!-- <v-row>
    <v-col cols="12" v-for="(itm, idt) in item.headers.filter(x => x.editable)" :key="idt">
      <v-text-field v-model="editedItem[itm.value]" :label="itm.text"></v-text-field>
    </v-col>
  </v-row> -->
<script>
// TODO: Segnalare mancanza di informazioni con errore su step
  import _ from 'lodash'
  import * as dot from 'dot-object'
  import VJsonEditor from 'v-jsoneditor'
  import { rivenditore } from '@/mixins/rivenditore.js'
  import impostazioni from '@/services/impostazioniService'
  import configurazioni from '@/services/configurazioniRemoteService'
  // import { marked } from 'marked'
  // import DialogConferma from '@/components/dialogConferma'
  export default {
    components: {
      VJsonEditor
    },
    props: {
      cliente: Object
    },
    mixins: [
      rivenditore
    ],    
    data (vm) {
      return {
        loading: false,
        saving: false,
        dialogConferma: false,
        titoloConferma: '',
        messaggioConferma: '',
        onlyOk: false,
        onConferma: null,
        confirmText: 'SI',
        switch1: false,
        elenco: [],
        stepper: 0,
        gruppi: [],
        setup: null,
        dialogTitle: '',
        dialogInfo: false,
        markdownInfo: '',
        textSearch: '',
        dialogEdit: false,
        dialogEditTitle: '',
        editInfo: [],
        editSubInfo: -1,
        editPosizione: '',
        menuData: {},
        dialogTable: {},
        dialogDeleteTable: {},
        editedIndex: -1,
        editedItem: {},
        itemEdit: {},
        itemEditIdx: -1,
        tabModel: {},
        baseDb: null,
        rules: {
          // required: [val => (val || '').length > 0 || 'Il campo è richiesto'],
          required: val => (val || '').length > 0 || 'Il campo è richiesto',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          nospaces: value => (value || '').indexOf(' ') < 0 || 'Gli spazi non sono ammessi',
          numero: value => !value || !isNaN(value) || 'Il valore deve essere numerico',
          numeropositivo: value => !value || !isNaN(value) && (value > 0) || 'Il valore deve essere un numero positivo',
          numeropositivonullo: value => !value || !isNaN(value) && (value >= 0) || 'Il valore deve essere un numero maggiore o uguale zero'       
        },
        optionsWeb: {
          mode: 'form',
          modes: ['code', 'form'],
          search: true,
          enableSort: false,
          enableTransform: false
        }
      }
    },
    async mounted() {
      let baseDb = null
      if (this.cliente && this.cliente.licenze) {
        const licenza = this.cliente.licenze[0]
        this.licenza = licenza
        baseDb = licenza.baseDb
      } else {
        baseDb = this.cliente.baseDb
      }
      this.baseDb = baseDb
      this.istanzaDb = await this.getIstanzaDb(this.cliente.reseller)
      await this.refreshImpostazioni()
/*       // let elenco = await impostazioni.leggeAlberoImpostazioni()
      let elenco = await impostazioni.getAllInfo()
      // const setup = elenco.find(x => x._setup)
      const setup = elenco.find(x => x._id === '_setup')
      this.setup = setup.dati
      // elenco = elenco.filter(x => !x._setup)
      elenco = elenco.filter(x => x._id !== '_setup')
      // TODO: Filtrare elenco in base a tag su prerequisiti
      let prerequisiti = this.cliente.prerequisiti
      elenco = elenco.filter(x => {
        let tutti = !_.get(prerequisiti, 'tipoInstallazione.riferimentoTagEsclusivo')
        let riferimento = false
        if (typeof x.dati.tag === 'string') {
          tutti = tutti && x.dati.tag === 'tutti'
          // riferimento = prerequisiti.tipoInstallazione.riferimentoTag.includes(x.dati.tag)
          // TODO: Verificare 
          riferimento = _.get(prerequisiti, 'tipoInstallazione.riferimentoTag')?.includes(x.dati.tag)
        } else if (Array.isArray(x.dati.tag)) {
          tutti = tutti && x.dati.tag.includes('tutti')
          // TODO: Verificare 
          const inters = _.intersection(_.get(prerequisiti, 'tipoInstallazione.riferimentoTag') || [], x.dati.tag)
          riferimento = inters.length > 0
        }
        return tutti || riferimento
      })
      this.elenco = elenco
      await this.popolaImpostazioni()
      // TODO: Rileggere impostazioni già salvate per utente --> aggiorna esistenti + Fare salvataggio
      this.stepper = 1
      await this.changeStep(this.stepper) */
    },
    computed: {
      elencoFiltrato() {
        if (this.textSearch && this.textSearch.length > 2) {
          return this.elenco.filter(x => x.dati.titolo.includes(this.textSearch) || x.dati.note.includes(this.textSearch) || x.dati.info.includes(this.textSearch))
        } else {
          return this.elenco
        }
      }
    },
    methods: {
      async refreshImpostazioni() {
      // let elenco = await impostazioni.leggeAlberoImpostazioni()
      let elenco = await impostazioni.getAllInfo()
      // const setup = elenco.find(x => x._setup)
      const setup = elenco.find(x => x._id === '_setup')
      this.setup = setup.dati
      // elenco = elenco.filter(x => !x._setup)
      elenco = elenco.filter(x => x._id !== '_setup')
      // TODO: Filtrare elenco in base a tag su prerequisiti
      let prerequisiti = this.cliente.prerequisiti
      elenco = elenco.filter(x => {
        let tutti = !_.get(prerequisiti, 'tipoInstallazione.riferimentoTagEsclusivo')
        let riferimento = false
        if (typeof x.dati.tag === 'string') {
          tutti = tutti && x.dati.tag === 'tutti'
          // riferimento = prerequisiti.tipoInstallazione.riferimentoTag.includes(x.dati.tag)
          // TODO: Verificare 
          riferimento = _.get(prerequisiti, 'tipoInstallazione.riferimentoTag')?.includes(x.dati.tag)
        } else if (Array.isArray(x.dati.tag)) {
          tutti = tutti && x.dati.tag.includes('tutti')
          // TODO: Verificare 
          const inters = _.intersection(_.get(prerequisiti, 'tipoInstallazione.riferimentoTag') || [], x.dati.tag)
          riferimento = inters.length > 0
        }
        return tutti || riferimento
      })
      this.elenco = elenco
      await this.popolaImpostazioni()
      // TODO: Rileggere impostazioni già salvate per utente --> aggiorna esistenti + Fare salvataggio
      this.stepper = 1
      await this.changeStep(this.stepper)
      },
      sourceSorted(source, sortedBy) {
        return sortedBy ? _.sortBy(source, [sortedBy]) : source
      },
      async salvaImpostazioni() {
        this.saving = true
        const step = this.stepper
        let tabelle = await this.getConfigurazioniStep(step)
        const idx = step - 1
        const gruppo = this.gruppi[idx]
        const elGruppo = this.elencoFiltrato.filter(x => x.dati.gruppo === gruppo)
        const istanzaDb = this.istanzaDb
        for (let el of elGruppo) {
          let cancellare = !el.dati.checked
          const posizione = el.dati.posizione
          let payload = _.get(el, 'dati.payload')
          // Toglie gli elementi dell'oggetto che non fanno parte della modale di impostazione
          if (Array.isArray(el.dati.payload)) {

          } else {
            const meta = el.dati.meta ? Object.keys(el.dati.meta) : []
            for (let ed of Object.keys(el.dati.payload)) {
              const editMeta = meta.find(x => x === ed)
              if (!editMeta) {
                delete payload[ed]
              }
            }
          }

          const arPosizione = posizione.split('.')

          const isPlaceholder = arPosizione[arPosizione.length - 1].startsWith('##')
          const placeholder = isPlaceholder ? arPosizione[arPosizione.length - 1] : ''
          if (isPlaceholder) {
            arPosizione.splice(-1)
          }

          const nomeDb = `${this.baseDb}_${arPosizione[0]}`
          let confId = ''
          let lenPosizione = arPosizione.length
          if (lenPosizione > 3) {
            lenPosizione = 3
            let p1 = {}
            let pp = arPosizione.slice(3).join('.')
            _.set(p1, pp, payload)
            payload = p1
          }
          var dati
          switch(lenPosizione) {
            case 1:
              dati = (tabelle.find(x => x.nome === nomeDb && x.documento === payload._id) || {}).dati
              this.updateSubConf(dati, payload, cancellare)
              // await configurazioni.modificaConfigurazione(nomeDb, payload, istanzaDb, cancellare)
              break;
            case 2:
              confId = arPosizione[1] || ''
              dati = (tabelle.find(x => x.nome === nomeDb && x.documento === confId) || {}).dati
              this.updateSubConf(dati, payload, cancellare)
              // await configurazioni.modificaConfigurazioneSub(nomeDb, confId, payload, istanzaDb, cancellare)
              break;
            case 3:
              confId = arPosizione[1] || ''
              const subConf = arPosizione[2] || ''
              dati = (tabelle.find(x => x.nome === nomeDb && x.documento === confId) || {}).dati
              this.updateSubConfDettaglio(dati, subConf, payload, cancellare, el.dati.meta.$array, placeholder)
              // await configurazioni.modificaConfigurazioneSubDettaglio(nomeDb, confId, subConf, payload, istanzaDb, cancellare)
              break;  
            default:
              break;
          }
        }
        
        for (let tb of tabelle) {
          console.log(tb.nome)
          await configurazioni.modificaConfigurazione(tb.nome, tb.dati, istanzaDb)
        }
        this.saving = false
        // TODO: Verificare cosa succede riaprendo e risalvando una configurazione dove sono presenti dei placeholder. 
      },
      async changeStep(step) {
        // Legge da db le impostazioni relative a questo step
        // Se nel db trovo il valore, prendo buono quello e non considero i valori letti da file. Prevedere forzatura per aggiornare da file
        // Per limitare il numero delle chiamate, legge prima tutte le impostazioni necessarie in maniera completa e non spezzate per proprietà ...

        this.loading = true
        let tabelle = await this.getConfigurazioniStep(step)

        const idx = step - 1
        const gruppo = this.gruppi[idx]
        const elGruppo = this.elencoFiltrato.filter(x => x.dati.gruppo === gruppo)     
        for (let el of elGruppo) {
          const posizione = el.dati.posizione
          const payload = el.dati.payload
          const arPosizione = posizione.split('.')

          const isPlaceholder = arPosizione[arPosizione.length - 1].startsWith('##')
          const placeholder = isPlaceholder ? arPosizione[arPosizione.length - 1] : ''
          if (isPlaceholder) {
            arPosizione.splice(-1)
          }

          const nomeDb = `${this.baseDb}_${arPosizione[0]}`
          let confId = ''
          let payloadDb = null
          let lenPosizione = arPosizione.length
          
          if (lenPosizione > 3) {
            lenPosizione = 3
          }
          var dati
          switch(lenPosizione) {
            case 1:
              // payloadDb = await configurazioni.leggeConfigurazione(nomeDb, payload, istanzaDb)
              payloadDb = tabelle.find(x => x.nome === nomeDb && x.documento === payload._id).dati
              if (payloadDb) {
                delete payloadDb.editInfo
                delete payloadDb._rev
              }
              break
            case 2:
              confId = arPosizione[1] || ''
              // payloadDb = await configurazioni.leggeConfigurazioneSub(nomeDb, confId, payload, istanzaDb)
              dati = (tabelle.find(x => x.nome === nomeDb && x.documento === confId) || {}).dati // TODO: mettere ?.dati su versione node opportuna
              var subArr
              if (typeof payload === 'string') {
                subArr = payload
              } else {
                subArr = Object.getOwnPropertyNames(payload).filter(x => !x.startsWith('__'))
              }
              payloadDb = dati ? this.getSubArr(subArr, dati) : null
              break
            case 3:
              confId = arPosizione[1] || ''
              const subConf = arPosizione.slice(2).join('.')
              // payloadDb = await configurazioni.leggeConfigurazioneSubDettaglio(nomeDb, confId, subConf, payload, istanzaDb)
              // dati = tabelle.find(x => x.nome === nomeDb && documento === confId)?.dati
              dati = (tabelle.find(x => x.nome === nomeDb && x.documento === confId) || {}).dati // TODO: mettere ?.dati su versione node opportuna
              payloadDb = _.get(dati, subConf) || null
              break; 
            default:
              break;
          }
          console.log(posizione, lenPosizione, payloadDb)

          if (Array.isArray(el.dati.payload)) {
            if (payloadDb && payloadDb.find(x => x.model === _.get(el, 'dati.payload[0].model'))) {
              if (!el.dati.checked) {
                el.dati.checked = true
              }
            }
          } else {
            if (payloadDb && !_.isEmpty(payloadDb)) {
              if (!el.dati.checked) {
                for (let p of Object.keys(el.dati.payload)) {
                  if (payloadDb.hasOwnProperty(p)) {
                    el.dati.checked = true
                  }
                }
              }
              _.merge(payloadDb, el.dati.payload) // TODO: Verificare in caso di eliminazione
              el.dati.payload = JSON.parse(JSON.stringify(payloadDb))
            }
          }
        }
        this.loading = false
      },
      updateSubConf(dati, subConf, cancellare) {
        let updatedConf = dati
        _.merge(updatedConf, subConf)
        // let updatedConf = { ...dati, ...subConf }
        if (cancellare) {
          try {
            const keys = Object.keys(subConf)
            for (let k of keys) {
              delete updatedConf[k]
            }
          } catch(err) {
            console.log(err)
          }
        }
      },
      updateSubConfDettaglio(dati, subConf, payload, cancellare, subArray, placeholder) {
        let updatedConf = dati
        let updatedSubConf = dati[subConf]
        if (!updatedSubConf) {
          updatedSubConf = Array.isArray(payload) ? [] : {}
        }
        if (Array.isArray(updatedSubConf)) {
          updatedConf[subConf] = [ ...updatedSubConf, ...payload ]
        } else {
          if (subArray) {
            const el = payload[subArray][0]
            if (!updatedConf[subConf][subArray]) {
              updatedConf[subConf][subArray] = []
            }
            let idx = updatedConf[subConf][subArray].findIndex(x => x.model === el.model)
            if (placeholder) {
              idx = updatedConf[subConf][subArray].findIndex(x => x.placeholder === placeholder)
            }
            if (cancellare) {
              if (idx !== -1) {
                updatedConf[subConf][subArray].splice(idx, 1)
              }
            } else {
              if (idx !== -1) {
                updatedConf[subConf][subArray][idx] = _.cloneDeep(el)
              } else {
                updatedConf[subConf][subArray].push(el)
              }
            }
            // updatedConf[subConf][subArray] = [ ...updatedConf[subConf][subArray], ...payload[subArray]]
          } else {
            updatedConf[subConf] = { ...updatedSubConf, ...payload }
          }

          if (cancellare && !subArray) {
            try {
              const keys = Object.keys(payload)
              for (let k of keys) {
                delete (updatedConf[subConf])[k]
              }
            } catch(err) {
              console.log(err)
            }
          }
        }
      },
      async getConfigurazioniStep(step) {
        const idx = step - 1
        const gruppo = this.gruppi[idx]
        const elGruppo = this.elencoFiltrato.filter(x => x.dati.gruppo === gruppo)
        const istanzaDb = this.istanzaDb
        let tabelle = []
        for (let el of elGruppo) {
          const posizione = el.dati.posizione
          const payload = el.dati.payload
          const arPosizione = posizione.split('.')
          const nomeDb = `${this.baseDb}_${arPosizione[0]}`
          let confId = ''
          let lenPosizione = arPosizione.length
          if (lenPosizione > 3) {
            lenPosizione = 3
          }
          let nomeDocumento = ''
          switch(lenPosizione) {
            case 1:
              nomeDocumento = payload._id
              break
            case 2:
              confId = arPosizione[1] || ''
              nomeDocumento = confId
              break
            case 3:
              confId = arPosizione[1] || ''
              nomeDocumento = confId
              break; 
            default:
              break;
          }
          if (tabelle.findIndex(x => x.nome === nomeDb && x.documento === nomeDocumento) === -1) {
            const dati = await configurazioni.leggeConfigurazione(nomeDb, { _id: nomeDocumento }, istanzaDb)
            tabelle.push({
              nome: nomeDb,
              documento: nomeDocumento,
              dati: dati || {}
            })
          }          
        }
        return tabelle
      },
      getSubArr(arrSub, conf)	{
        let configurazione = _.cloneDeep(conf)
        if (arrSub) {
          if (Array.isArray(arrSub)) {
            const items = Object.getOwnPropertyNames(configurazione)
            for (let item of items) {
              if (!arrSub.includes(item)) {
                delete configurazione[item]
              }
            }
            return configurazione
          } else if (arrSub.indexOf(',') === -1) {
            return configurazione[arrSub] || []
          } else {
            const confs = arrSub.split(',')
            const items = Object.getOwnPropertyNames(configurazione)
            for (let item of items) {
              if (!confs.includes(item)) {
                delete configurazione[item]
              }
            }
            return configurazione || []
          }
        } else {
          return configurazione
        }
      },
      moveUpTableItem(item, items) {
        const mFrom = items.indexOf(item)
        if (mFrom > 0) {
          const mTo = mFrom - 1
          items.splice(mTo, 0, items.splice(mFrom, 1)[0])
        }
      },
      moveDownTableItem(item, items) {
        const mFrom = items.indexOf(item)
        if (mFrom < items.length) {
          const mTo = mFrom + 1
          items.splice(mTo, 0, items.splice(mFrom, 1)[0])
        }
      },
      editTableItem (item, idx, items) {
        this.editedIndex = items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogTable[idx] = true
      },
      deleteTableItem (item, idx, items) {
        this.editedIndex = items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDeleteTable[idx] = true
      },
      closeTable(idx) {
        this.dialogTable[idx] = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, {})
          this.editedIndex = -1
        })
      },
      saveTable(idx, items) {
        if (this.editedIndex > -1) {
          // mettere anche su editInfo
          Object.assign(items[this.editedIndex], this.editedItem)
          this.editInfo[idx].value[this.editedIndex] = this.editedItem // Verificare
        } else {
          this.editInfo[idx].value.push(this.editedItem)
          items.push(this.editedItem)
        }
        this.closeTable(idx)  
      },
      closeDelete(idx) {
        this.dialogDeleteTable[idx] = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, {})
          this.editedIndex = -1
        })
      },
      deleteItemConfirm(idx, items) {
        items.splice(this.editedIndex, 1)
        this.closeDelete(idx)        
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },      
      onErrorWeb() {
        console.log('Errore da gestire')
      },
      elencoCategorie(gruppo) {
        const elGruppo = this.elencoFiltrato.filter(x => x.dati.gruppo === gruppo)
        const elCat = elGruppo.map(x => x.dati.categoria)
        if (elCat.includes('Generale')) {
          let elenco = _.uniq(elCat.filter(x => x !== 'Generale')).sort()
          // console.log(['Generale', ...elenco])
          return ['Generale', ...elenco]
        } else {
          return _.uniq(elCat).sort()
        }
      },
      elencoDati(gruppo, categoria) {
        let elenco = this.elencoFiltrato.filter(x => x.dati.gruppo === gruppo && x.dati.categoria === categoria).map(x => x.dati)

        // Per la gestione delle configurazioni di default da non mostrare all'utente, si aggiunge il valore "hidden: true" nella root della configurazione
        elenco = elenco.filter(x => !x.hidden)
        return elenco       
      },
      mostraInfo(item) {
        // TODO: Nella nuova versione installare 'marked' per il parsing dell'md
        // const html = marked.parse(item.info)
        const html = item.info
        this.markdownInfo = html
        this.dialogTitle = item.titolo
        this.dialogInfo = true
      },
      infoConfirm() {
        this.dialogInfo = false
      },
      async popolaImpostazioni() {
        /* TODO:
          1) Valutare se l'impostazione è visibile in base al tipo di installazione scelta
          2) Se è required (cioè obbligatoria)
          3) Se è di default --> valore true ma modificabile
        */
        while(this.gruppi.length > 0) { this.gruppi.pop() }
        this.setup.gruppi.forEach(x => this.gruppi.push(x))

        for (let item of this.elenco.map(x => x.dati)) {
          item.checked = item.required || item.default
          item.disabled = item.required
        }
      },
      operazioneEseguita(messaggio) {
        this.titoloConferma = 'Operazione eseguita'
        this.messaggioConferma = messaggio || 'Operazione eseguita con successo ! '
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.confirmText = 'OK'
        this.dialogConferma = true
      },
      operazioneEseguitaConfirm() {
        this.dialogConferma = false
        this.confirmText = 'SI'
        this.onlyOk = false
      },
      editConfirm() {
        this.aggiornaValore(this.itemEdit, this.editInfo)
        this.itemEditIdx = -1
        this.dialogEdit = false
      },
      aggiornaValore(item, editInfo) {
        let value = null
        const posizione = item.posizione
        const payload = item.payload
        const arPosizione = posizione.split('.')
        let payloadDb = null, tgt1 = null, itm = null
        let lenPosizione = arPosizione.length
        var confId
        if (lenPosizione > 3) {
          lenPosizione = 3
        }
        dot.keepArray = true
        // TODO: Verificare, probabilemente si può togliere lo switch
        switch(lenPosizione) {
          case 1:
            tgt1 = dot.dot(payload)
            itm = Object.keys(tgt1)
            payloadDb = {}
            for (let it of itm) {
              let idx = itm.indexOf(it)
              const info = editInfo.find(x => x.key === it)
              if (info) {
                value = info.value
                dot.str(itm[idx], value, payloadDb)
              }
            }
            break
          case 2:
            confId = arPosizione[1] || ''
            payloadDb = value
            break
          case 3:
            tgt1 = dot.dot(payload)
            itm = Object.keys(tgt1)
            payloadDb = {}
            for (let it of itm) {
              let idx = itm.indexOf(it)
              const info = editInfo.find(x => x.key === it)
              if (info) {
                value = info.value
                dot.str(itm[idx], value, payloadDb)
              }
            }            
/*             tgt1 = dot.dot(payload)
            itm = Object.keys(tgt1)
            payloadDb = {}
            for (let it of itm) {
              let idx = itm.indexOf(it)
              value = editInfo[idx].value
              dot.str(itm[idx], value, payloadDb)
            } */
            break; 
          default:
            break;
        }
        if (payloadDb) {
          // el.dati.payload = JSON.parse(JSON.stringify(payloadDb))
          item.payload = JSON.parse(JSON.stringify(payloadDb))
        }
        // }
      },
      async aggiungiValore(ed, payload, em, isObject, tabIndex, path, livello) {
        path = path.slice(0, livello)
        path.push(ed)
        livello++
        var key
        if (!isObject) {
          key = path.join('.')
          console.log('key', key)
          // path = path.slice(0, livello - 1)
        }   
        if (isObject) {
          if (em.edit === 'tabs') {
            this.editInfo.push({
              label: (em && em.$label) || ed,
              type: 'tabs',
              items: payload.length
              // editInfo: []
            })
            for (let idx = 0; idx < payload.length; idx++) {
              this.editSubInfo = idx
              this.editInfo[idx].editInfo = []
              for (let ed1 of Object.keys(em.tabItems)) {
                const em1 = em.tabItems[ed1]
                const isObject = (typeof (payload[idx][ed1]) === 'object') && !(Array.isArray(payload[idx][ed1]) && em1.edit === 'combo') && !['hidden', 'date', 'list', 'editor'].includes(em1.edit)
                path = await this.aggiungiValore(ed1, payload[idx][ed1], em1, isObject, idx, path, livello)
              }
            }
          } else {
            this.editInfo.push({
              label: (em && em.$label) || ed,
              type: 'group'
            })
            
            // path = path.slice(0, livello)
            for (let ed1 of Object.keys(payload)) {
              const em1 = em[ed1]
              if (!em1) {
                console.log('***** Impostazione non trovata ****', ed1)
                continue
              }
              const isObject = (typeof (payload[ed1]) === 'object') && !(Array.isArray(payload[ed1]) && em1.edit === 'combo') && !['hidden', 'date', 'list', 'editor'].includes(em1.edit)
              path = await this.aggiungiValore(ed1, payload[ed1], em1, isObject, tabIndex, path, livello)
            }
          }
          return path
        }    
        let info = {
          label: ed,
          value: payload,
          type: 'text',
          key: key // path.join('.')
        }
        // path = []
        info.rules = this.rulesValidation(em)
        if (em) {
          info.required = em.required
          info.hint = em.hint || ''
          info.label = em.label || info.label
          info.persistent = em.persistent
          info.type = em.edit || info.type
          if (em.edit === 'list') {
            info.headers = em.headers
            info.items = payload.map((item, idx) => {
              if (typeof item === 'object') {
                return item
              } else {
                return { index: idx, value: item }
              }
            })
          } else {
            if (em.edit === 'select' && typeof em.source === 'string') {
              var source = []
              const posizione = em.source.split('.')
              const nomeDb = `${this.baseDb}_${posizione[0]}`
              const conf = await configurazioni.leggeConfigurazione(nomeDb, { _id: posizione[1] })
              /// TODO: Gestire subArray --> posizione.length > 3
              if (posizione.length === 2) {
                source = conf
              } else if (posizione.length === 3) {
                source = conf[posizione[2]]
              } 
              info.source = source
            } else {
              info.source = em.source || []
            }
          }
        }
        // console.log('tabIndex', tabIndex, this.editSubInfo)
        if (tabIndex !== undefined && this.editSubInfo !== -1) {
          this.editInfo[this.editSubInfo].editInfo.push(info)
        } else {
          this.editInfo.push(info)
        }
        return path
        // console.log(this.editInfo)
      },
      async modificaValori(item, idx) {
        this.itemEditIdx = idx
        this.itemEdit = item
        this.editInfo = []
        const meta = item.meta ? Object.keys(item.meta) : []
        for (let ed of Object.keys(item.payload)) {
          const editMeta = meta.find(x => x === ed)
          if (editMeta) {
            const em = editMeta ? item.meta[editMeta] : null
            const isObject = (typeof (item.payload[ed]) === 'object') && !(Array.isArray(item.payload[ed]) && em.edit === 'combo') && !['hidden', 'date', 'list', 'editor'].includes(em.edit)
            await this.aggiungiValore(ed, item.payload[ed], em, isObject, null, [], 0)
          }
        }
        this.dialogEditTitle = item.titolo
        this.editPosizione = item.posizione
        this.dialogEdit = true        
      },
      rulesValidation(item) {
        if (!item) return []
        let rl = []
        if (item.required) {
          rl.push(this.rules.required)
        }
        if (item.isEmail) {
          rl.push(this.rules.email)
        }
/*         if (item.maxlength) {
          rl.push(this.rules.counter)
        } */
        if (item.noSpaces) {
          rl.push(this.rules.nospaces)
        }
/*         if (item.partitaiva) {
          rl.push(this.rules.partitaiva)
        }
        if (item.codicefiscale) {
          rl.push(this.rules.codicefiscale)
        } */
        if (item.isNumero) {
          rl.push(this.rules.numero)
        }
        if (item.isNumeroPositivo) {
          rl.push(this.rules.numeropositivo)
        }        
        if (item.isNumeroPositivoNullo) {
          rl.push(this.rules.numeropositivonullo)
        }  
        return rl
      }
/*       modificaValoriCopia(item) {
        this.editInfo = []
        const meta = item.meta ? Object.keys(item.meta) : []
        for (let ed of Object.keys(item.payload)) {
          let info = {
            label: ed,
            value: item.payload[ed],
            type: 'text'
          }
          // TODO: Cercare type, hind, edit su meta
          const editMeta = meta.find(x => x === ed)
          if (editMeta) {
            const em = item.meta[editMeta]
            info.required = em.required
            if (info.required) {
              info.rules = _.get(this, 'rules.required')
            }
            info.hint = em.hint || ''
            info.label = em.label || info.label
            if (em.rules) {
              info.rules = _.get(this, `rules.${em.rules}`)
            }
            info.persistent = em.persistent
            info.type = em.edit || info.type
          }
          
          this.editInfo.push(info)
        }
        this.dialogEditTitle = item.titolo
        this.editPosizione = item.posizione
        this.dialogEdit = true
      } */
    }    
  }
</script>

<style>
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
  .stepperSearch {
    position: absolute;
    width: 15%;
    left: auto;
    right: 10px;
    top: 10px;
  }
  .salvaImpostazioni {
    position: absolute;
    left: auto;
    right: 10px;
    top: auto;
    bottom: 10px;
  }  
</style>
