<template>
  <div>
    <v-data-table
      v-model="selected"
      :show-select="totaleClienti > 0"
      item-key="codice"
      :headers="headers"
      :items="clientiSorted"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      @click:row="clickRow"
      @dblclick:row="dblclickRow"

      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 50, 100]
      }"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Clienti e Licenze</v-toolbar-title>
<!--    <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="onNuovoCliente">Nuovo Cliente</v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Confermi cancellazione cliente?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">SI</v-btn>
              <v-btn color="blue darken-1" text @click="closeDelete">NO</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <dialog-errore :show="dialogErrore" title="Errore aggiornamento Cliente" />
      </v-toolbar>
      <filtri @ricerca="onRicerca"/>
    </template>
    <template v-slot:[`item.dataScadenza`]="{ item }">
      <span :class="[ item.segnalaScadenza ? 'red--text': '' ]">{{ item.dataScadenza | date }}</span>
    </template>
    <template v-slot:[`item.attivo`]="{ item }">
      <v-simple-checkbox v-model="item.attivo" disabled />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">mdi-pencil</v-icon>
        </template>
        <span>Modifica il cliente</span>
      </v-tooltip>
<!--       <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small class="mr-2" @click="duplicateItem(item)" v-bind="attrs" v-on="on">mdi-content-duplicate</v-icon>
        </template>
        <span>Duplica il cliente</span>
      </v-tooltip> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small @click="deleteItem(item)" v-bind="attrs" v-on="on">mdi-delete</v-icon>
        </template>
        <span>Cancella il cliente</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="getDataFromApi">Ricarica</v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="clientiSelected">
        <v-tabs dense v-model="tab[item.codice]">
          <v-tab class="clientiSelected">Licenze{{item.name}}</v-tab>
          <v-tab class="clientiSelected" :disabled="isLicenza(item)">Database</v-tab>
          <v-tab class="clientiSelected" :disabled="isDatabase(item)">Configurazioni</v-tab>
          <v-tab class="clientiSelected" :disabled="isDatabase(item)">Utenti</v-tab>
          <v-tab class="clientiSelected" :disabled="isDatabase(item)">Installazione</v-tab>
          <v-tab class="clientiSelected" :disabled="isDatabase(item)">Migrazione</v-tab>
          <v-tab class="clientiSelected" :disabled="isDatabase(item)">Utility</v-tab>
          <v-tab class="clientiSelected" :disabled="isDatabase(item)">eCommerce</v-tab>
          <v-tab class="clientiSelected" :disabled="isDatabase(item)">Prerequisiti</v-tab>
          <v-tab class="clientiSelected" :disabled="isDatabase(item)">Impostazioni</v-tab>
          <v-tab class="clientiSelected" :disabled="isDatabase(item)" v-if="licenzaRistorazione">Ristorazione<v-btn icon @click="triggerRistorazione++"><v-icon>mdi-refresh</v-icon></v-btn></v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab[item.codice]">
          <v-tab-item>
            <dettaglio-licenze :licenze="item.licenze" :codiceCliente="item.codice" :reseller="item.reseller" @update-licenza="updateLicence" @new-licenza="newLicence"/>
          </v-tab-item>
          <v-tab-item>
            <dettaglio-database :cliente="item" :elencoSorgenti="elencoSorgenti" @update-credentials="updateCredentials"/>
          </v-tab-item>
          <v-tab-item>
            <dettaglio-configurazioni :cliente="item" />
          </v-tab-item>          
          <v-tab-item>
            <dettaglio-utenti :utenti="item.utenti" :codiceCliente="item.codice" @update-user="updateUser" @new-user="newUser" @delete-user="deleteUser"/>
          </v-tab-item>
          <v-tab-item>
            <dettaglio-installazione :cliente="item" />
          </v-tab-item>
          <v-tab-item>
            <dettaglio-migrazione :cliente="item" />
          </v-tab-item>
          <v-tab-item>
            <dettaglio-utility :cliente="item" />
          </v-tab-item>
          <v-tab-item>
            <dettaglio-ecommerce :siti="item.utenti" :codiceCliente="item.codice" @update-sito="updateUser" @new-sito="newUser" @delete-sito="deleteUser"/>
          </v-tab-item>
          <v-tab-item>
            <prerequisiti :cliente="item" @aggiorna="onAggiornaPrerequisiti"></prerequisiti>
          </v-tab-item>          
          <v-tab-item>
            <dettaglio-impostazioni :cliente="item"></dettaglio-impostazioni>
          </v-tab-item>
          <v-tab-item>
            <ristorazione :apikey="apikey(item)" :trigger="triggerRistorazione" @update-licenza="onUpdateLicenzaDispositivo" @delete-licenza="onDeleteLicenzaDispositivo"></ristorazione>
          </v-tab-item>
          <v-tab-item></v-tab-item> <!-- Step Procedura setup + Stampa lista lavoro (apre pagina stampabile con le info per l'installazione e le procedura da effettaure) -->
        </v-tabs-items>
      </td>
    </template>    
    </v-data-table>
    <!-- <speed-dial /> -->
  </div>
</template>

<script>
  import _ from 'lodash'

  import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  import { rivenditore } from '@/mixins/rivenditore.js'
  import DialogErrore from '@/components/dialogErrore'
  import Filtri from './filtri'
  import moment from 'moment'

  import DettaglioLicenze from './elementi/licenze'
  import DettaglioDatabase from './elementi/database'
  import DettaglioUtenti from './elementi/utenti'
  import DettaglioMigrazione from './elementi/migrazione'
  import DettaglioUtility from './elementi/utility'
  import DettaglioConfigurazioni from './elementi/configurazioni'
  import DettaglioInstallazione from './elementi/installazione.vue'
  import DettaglioEcommerce from './elementi/ecommerce.vue'
  import DettaglioImpostazioni from './elementi/impostazioni.vue'
  import Prerequisiti from './elementi/prerequisiti.vue'
  import Ristorazione from './elementi/ristorazione'

  import clienti from '@/services/clientiService.js'
  import licenze from '@/services/licenzeService.js'
  import dispositivi from '@/services/dispositiviService.js'
  import impostazioni from '@/services/impostazioniService.js'
  
  export default {
    components: {
      DialogErrore,
      DettaglioLicenze,
      DettaglioDatabase,
      DettaglioUtenti,
      DettaglioMigrazione,
      DettaglioUtility,
      DettaglioConfigurazioni,
      DettaglioInstallazione,
      DettaglioEcommerce,
      DettaglioImpostazioni,
      Prerequisiti,
      Ristorazione,
      Filtri
    },
    mixins: [
      verificaLicenza,
      rivenditore
    ],
    data () {
      return {
        totaleClienti: 0,
        clienti: [],
        selected: [],
        loading: true,
        options: {},
        headers: [
          { text: 'Codice', value: 'codice', align: 'start' },
          { text: 'Ragione sociale', value: 'ragioneSociale' },
          { text: 'Postazioni', value: 'numeroPostazioni' },
          { text: 'Usate', value: 'numeroPostazioniUsate' },
          { text: 'Data scadenza', value: 'dataScadenza' }, 
          { text: 'Attivo', value: 'attivo' }, 
/*           { text: 'Base DB', value: 'baseDb' },
          { text: 'Data scadenza', value: 'dataScadenza' },          
          { text: 'Attivo', value: 'attivo' }, */
          { text: 'Actions', value: 'actions', sortable: false },
          { text: '', value: 'data-table-expand' }
        ],
        expanded: [],
        singleExpand: false,
        dialogErrore: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: null,
        defaultItem: null,
        filtri: null,
        tab: {},
        giorniSegnalaScadenza: 20,
        istanzaDb: '',
        elencoSorgenti: [],
        triggerRistorazione: 1
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuovo Cliente' : 'Modifica Cliente'
      },
      customerSaved() {
        return this.$store.getters['clienti/saved']
      },
      refreshList() {
        return this.$store.getters['clienti/refresh']
      },
      clientiSorted() {
/*         const els = this.clienti
        const elSorted = els.sort(dynamicSortMultiple('ragioneSociale')) */
        return _.sortBy(this.clienti, ['ragioneSociale'])
/*         return elSorted */
      },
      isUbico() {
        const utente = this.$store.getters['utenti/user']
        return utente.company === 'ubico'
      },      
      licenzaRistorazione() {
        return this.isUbico // TODO: Verificare in base alla licenza del cliente
      }
    },
    watch: {
      options: {
        async handler () {
          await this.getDataFromApi()
        },
        deep: true
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      async customerSaved(value) {
        if (value) {
          await this.save()
        }
      },
      async refreshList(value) {
        if (value) {
          await this.getDataFromApi()
          this.$store.commit('clienti/CLEAR_REFRESH')
        }
      }
    },
    async created() {
      this.defaultItem = await clienti.getModello()
    },
    async mounted () {
      await this.verificaModuliLocali()
      if (!this.$store.getters['utenti/isLoggedIn']) {
        this.$router.replace('/')
      }
      await this.getDataFromApi()
    },
    methods: {
      apikey(item) {
        return _.get(item, 'licenze[0].apikey')        
      },
      isLicenza(item) {
        return item.licenze.length === 0
      },
      isDatabase(item) {
        // TODO: Verificare che siano stati creati i database, altrimenti le successive impostazioni non funzionano correttamente
        return this.isLicenza(item)
      },
      async onRicerca(filtri) {
        this.filtri = filtri
        // this.options.page = 1
        await this.getDataFromApi()
      },
      async getDataFromApi () {
        try {
          this.loading = true
          let clientiRaw = await clienti.getClienti()
          this.elencoSorgenti = clientiRaw.map(x => { return { codice: x.codice, ragioneSociale: x.ragioneSociale, reseller: x.reseller, baseDb: x.licenze &&  x.licenze.length > 0 && x.licenze[0].baseDb }})
          if (this.filtri.testo) {
            const ft = this.filtri.testo.toLowerCase()
            clientiRaw = clientiRaw.filter(x => x.codice.toLowerCase().includes(ft) || x.ragioneSociale.toLowerCase().includes(ft))
          }
          this.clienti = this.datiVisualizzazione(clientiRaw)
          if (this.filtri.soloAttivi) {
            this.clienti = this.clienti.filter(x => x.attivo)
          }
          if (this.filtri.inScadenza) {
            this.clienti = this.clienti.filter(x => moment(x.dataScadenza).diff(moment(), 'days') < this.giorniSegnalaScadenza )
          }
          this.totaleClienti = this.clienti.length
        } catch(err) {
          this.clienti = []
          this.totaleClienti = 0
        } finally {
          this.loading = false
        }
      },
      onNuovoCliente() {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        this.$store.commit('clienti/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      editItem (item) {
        this.editedIndex = this.clienti.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$store.commit('clienti/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      duplicateItem(item) {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, item)
        this.editedItem._id = ''
        delete this.editedItem._rev
        this.$store.commit('clienti/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      deleteItem (item) {
        this.editedIndex = this.clienti.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.clienti.splice(this.editedIndex, 1)
        // TODO: ATTENZIONE VA VERIFICATO CHE NON ABBIA STORIA
        if (!await clienti.cancellaCliente(this.editedItem)) {
          this.dialogErrore = true
        }
        this.closeDelete()
      },

      close () {
        this.$store.commit('SET_RIGHT_DRAWER', false)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async save () {
        this.$store.commit('clienti/CLEAR_CLIENTE_SAVED')
        this.editedItem = this.$store.getters['clienti/cliente']
        if (this.editedIndex > -1) {
          this.editedItem = this.deleteDatiVisualizzazione(this.editedItem)
          Object.assign(this.clienti[this.editedIndex], this.editedItem)
          if (!await clienti.modificaCliente(this.editedItem)) {
            this.dialogErrore = true
            return
          }
        } else {
          let cliente = this.editedItem
          if (!cliente.reseller) {
            // Se isUbico, il reseller è impostato nel drawer, altrimenti prende quello dell'utente
            cliente.reseller = this.getReseller()
          }
          cliente.isMoleculer = true // Solo per distinguere quale setup scaricare
          this.clienti.push(cliente)
          if (!await clienti.creaCliente(cliente)) {
            this.dialogErrore = true
            return
          }
        }
        this.close()
        // va fatto il refresh della lista per prendere gli aggiornamenti
        await this.getDataFromApi()
      },
      async updateCredentials(credential) {
        /* TODO: Verificare procedura migliore
        1) Creo credenziali su generazione licenza e poi associo ai nuovi db vuoti o copie (adesso questo)
        2) Creo le credenziali al momento della creazionedei db, ma poi devo aggiornare il cliente e non so qual è la licenza (suppongo la prima...)
        */
/*         let cliente = this.clienti.find(x => x.codice === credential.codice)
        if (cliente) {
          cliente.licenze[0].cloudantApikey = credential.key
          cliente.licenze[0].cloudantPassword = credential.password
        } */
        // await clienti.modificaCliente(cliente)
      },
      erroreConfirm() {
        this.dialogErrore = false
      },
      clickRow(item, options) {

      },
      dblclickRow(event, options) {
        const item = options.item
        this.editedIndex = this.clienti.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$store.commit('clienti/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', true)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      datiVisualizzazione(clienti) {
        const elaborati = clienti.map(el => {
          try {
            const recapito = el.recapiti && el.recapiti.length > 0 ? el.recapiti.find(x => x.tipo === 'principale') : null
            const indirizzo = recapito ? `${recapito.indirizzo}, ${recapito.localita} ${recapito.cap} - ${recapito.provincia}` : ''
            const contatto = el.contatti && el.contatti.length > 0 ? el.contatti.find(x => x.tipo === 'titolare') : null
            const titolare = contatto ? `${contatto.nome} ${contatto.cognome} - cell.${contatto.cellulare} - email: ${contatto.email}` : ''
            el.utenti = el.utenti || []

            el.numeroPostazioni = 1
            el.numeroPostazioniUsate = 1

            // TODO: Verificare se licenza multipla
            if (el.licenze && el.licenze.length > 0) {
              el.dataScadenza = el.licenze[0].dataScadenza
              el.attivo = el.licenze[0].attivo
              el.segnalaScadenza = moment(el.dataScadenza).diff(moment(), 'days') < this.giorniSegnalaScadenza
            } else {
              el.dataScadenza = new Date()
              el.attivo = false
              el.segnalaScadenza = false
            }

            return { ...el, c_recapito: recapito, c_indirizzo: indirizzo, c_contatto: contatto, c_titolare: titolare }
          } catch (err) {
            console.log(err)
          }          
        })
        return elaborati
      },
      deleteDatiVisualizzazione(cliente) {
        let locCliente = {...cliente}
        // Eventuale aggiornamento dati modificati
        delete locCliente.c_recapito
        delete locCliente.c_indirizzo
        delete locCliente.c_contatto
        delete locCliente.c_titolare
        return locCliente
      },
      async newLicence(codiceCliente, licenza) {
        await licenze.creaLicenza(codiceCliente, licenza)
      },
      async updateLicence(codiceCliente, licenza) {
        await licenze.aggiornaLicenza(codiceCliente, licenza)
      },
      async newUser(codiceCliente, utente) {
        await licenze.creaUtente(codiceCliente, utente)
      },
      async updateUser(codiceCliente, utente) {
        await licenze.aggiornaUtente(codiceCliente, utente)
      },
      async deleteUser(codiceCliente, utente) {
        await licenze.cancellaUtente(codiceCliente, utente)
      },
      async onAggiornaPrerequisiti(codiceCliente, prerequisiti) {
        await impostazioni.aggiornaPrerequisiti(codiceCliente, prerequisiti)
      },
      async onUpdateLicenzaDispositivo(apikey, licenzaDispositivo) {
        await dispositivi.updateLicenzaDispositivo(apikey, licenzaDispositivo)
      },
      async onDeleteLicenzaDispositivo(apikey, licenzaDispositivo) {
        await dispositivi.deleteLicenzaDispositivo(apikey, licenzaDispositivo.codice)
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.clientiSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #1976d2 !important;
  }

</style>
