<template>
  <v-container>
    <v-btn :disabled="!daSalvare" class="btn-da-salvare" color="success" @click.stop="onSalvaModifiche">Salva modifiche</v-btn>
    <v-row dense>
      <v-col cols="12" md="4" sm="12">
        <v-card :loading="loading" >
          <v-card-title>{{ titolo }}</v-card-title>
          <v-card-text>
            {{ descrizione }}
          </v-card-text>
          <v-card-text>
            <v-select class="pl-5 pr-5" :items="tipiInstallazione" v-model="tipo"
              label="Tipo installazione"
              item-text="descrizione"
              item-value="codice"
              return-object
              :disabled="changeDisabled"
              @change="onChangeTipoInstallazione">
            </v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="!isTotem" title="Aggiunge una ulteriore postazione" @click="onNuovaPostazione">Nuova postazione</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="licenzaTotem" title="Aggiunge un ulteriore totem" @click="onNuovoTotem" color="secondary">Nuovo totem</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12" v-if="tipo">
        <v-card>
          <v-card-title>Descrizione ed uso</v-card-title>
          <v-card-text>
            {{ tipo.informazioni }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="4" sm="12" v-for="(postazione, idx) in postazioni" :key="idx">
        <v-card >
          <v-card-title>
            {{ 'Postazione' }} {{ idx + 1 }}:&nbsp;<strong>{{ postazione.nome }}</strong>
            <v-btn icon @click.stop="onRimuoviPostazione(postazione)" title="Rimuovi postazione" ><v-icon small>mdi-delete-outline</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="toggleLocali(idx)" title="Impostazioni locali" ><v-icon small>mdi-cog-outline</v-icon></v-btn>
          </v-card-title>
          <template v-if="mostraLocali(triggerAggiorna, idx)">
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Codice postazione</td><td><v-text-field readonly dense placeholder="Codice postazione" v-model="postazione.id" hide-details>
                        <template v-slot:append-outer><v-icon :title="postazione.systemInfo ? 'Licenza installata' : 'Licenza non installata'" @click="liberaLicenza(postazione)">{{ postazione.systemInfo ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline' }}</v-icon></template>
                      </v-text-field></td>
                    </tr>                    
                    <tr>
                      <td>Nome</td><td><v-text-field dense placeholder="Nome postazione" v-model="postazione.nome" hide-details @change="daSalvare = true"></v-text-field></td>
                    </tr>
                    <tr>
                      <td>Descrizione</td><td><v-text-field dense placeholder="Descrizione" v-model="postazione.descrizione" hide-details @change="daSalvare = true"></v-text-field></td>
                    </tr>
                    <tr>
                      <td>Usa solo database in cloud</td><td><v-switch inset v-model="postazione.soloCloud" @change="impostaDefault(postazione)"></v-switch></td>
                    </tr>
                    <tr>
                      <td>DB user</td><td><v-text-field :disabled="postazione.soloCloud" dense placeholder="Utente database locale" v-model="postazione.couchDbUser" hide-details @change="daSalvare = true"></v-text-field></td>
                    </tr>                          
                    <tr>
                      <td>DB password</td><td><v-text-field :disabled="postazione.soloCloud" dense placeholder="Password database locale" v-model="postazione.couchDbPassword" hide-details @change="daSalvare = true"></v-text-field></td>
                    </tr>                  
                    <tr>
                      <td title="Codici per la gestione delle notifiche push">Public Vapid Key</td>
                      <td>
                        <v-textarea auto-grow class="text-caption" dense placeholder="chiave Vapid pubblica" title="Codici per la gestione delle notifiche push" v-model="postazione.publicVapidKey" hide-details @change="daSalvare = true" rows="3">
                          <template v-slot:prepend-inner>
                            <v-icon color="success" @click="generaVapidKeys(idx)" title="Genera una nuova coppia di chiavi per le notifiche">mdi-cloud-key-outline</v-icon>
                          </template>                        
                        </v-textarea>
                      </td>
                    </tr>
                    <tr>
                      <td title="Codici per la gestione delle notifiche push">Private Vapid Key</td>
                      <td>
                        <v-textarea auto-grow class="text-caption" dense placeholder="chiave Vapid privata" title="Codici per la gestione delle notifiche push" v-model="postazione.privateVapidKey" hide-details @change="daSalvare = true" rows="2">
                          <template v-slot:prepend-inner>
                            <v-icon color="transparent">mdi-cloud-key-outline</v-icon>
                          </template> 
                        </v-textarea>
                      </td>
                    </tr>                    
                    <tr>
                      <td>Note</td><td><v-textarea auto-grow class="text-caption" dense placeholder="Note configurazione" v-model="postazione.note" hide-details clearable rows="3" @change="daSalvare = true"></v-textarea></td>
                    </tr>                                          
                  </tbody>
                </template>
              </v-simple-table>                
              <v-row dense>
                <v-col cols="12">
                  <h5 class="mt-5 mb-3">STAMPANTI FISCALI<v-btn icon @click.stop="aggiungiStampanteFiscale(postazione)" title="Aggiungi stampante fiscale" ><v-icon small>mdi-plus-circle-outline</v-icon></v-btn></h5>
                  <template v-if="postazione.stampanti.fiscali.length > 0">
                    <v-row dense v-for="(stampante, sidx) in postazione.stampanti.fiscali" :key="sidx" class="pl-3 pr-3">
                      <v-col><v-select :items="setup.moduli.stampanti.fiscali" dense v-model="stampante.tipo" label="Tipo" item-text="descrizione" item-value="tipo" @change="onChangeTipoStampanteFiscale(postazione, sidx)"></v-select></v-col>
                      <v-col><v-text-field placeholder="IP Stampante" v-model="stampante.ip" dense label="IP Stampante" hide-details></v-text-field></v-col>
                      <v-col cols="1"><v-btn icon @click.stop="rimuoviStampanteFiscale(postazione, stampante)" title="Rimuovi stampante fiscale" ><v-icon small>mdi-delete-outline</v-icon></v-btn></v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <h5 class="mt-5 mb-3">STAMPANTI NON FISCALI<v-btn icon @click.stop="aggiungiStampanteNonFiscale(postazione)" title="Aggiungi stampante non fiscale" ><v-icon small>mdi-plus-circle-outline</v-icon></v-btn></h5>
                  <template v-if="postazione.stampanti.comande.length > 0">
                    <v-row dense v-for="(stampante, sidx) in postazione.stampanti.comande" :key="sidx" class="pl-3 pr-3">
                      <v-col><v-select :items="setup.moduli.stampanti.comande" dense v-model="stampante.tipo" label="Tipo" item-text="descrizione" item-value="tipo" @change="onChangeTipoStampanteNonFiscale(postazione, sidx)"></v-select></v-col>
                      <v-col><v-text-field placeholder="IP Stampante" v-model="stampante.ip" dense label="IP Stampante" hide-details></v-text-field></v-col>
                      <v-col cols="1"><v-btn icon @click.stop="rimuoviStampanteNonFiscale(postazione, stampante)" title="Rimuovi stampante non fiscale" ><v-icon small>mdi-delete-outline</v-icon></v-btn></v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Informazioni database</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panel-content>Da completare</v-expansion-panel-content>
                  </v-expansion-panel-content>
                </v-expansion-panel>              
                <v-expansion-panel>
                  <v-expansion-panel-header>Informazioni hardware installato</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-json-editor v-model="hwdInfo" :options="optionsWeb" :plus="true" history style="height: 100%;" ref="jsonEditor"/>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Elenco interventi effettuati</v-expansion-panel-header>
                  <v-expansion-panel-content>Da completare</v-expansion-panel-content>
                </v-expansion-panel>              
              </v-expansion-panels>
            </v-card-text>
          </template>
          <template v-else>
            <v-card-subtitle>Modificare solo i valori che devono essere diversi dalla configurazione generale su database</v-card-subtitle>
            <v-card-text>
              <v-expansion-panels multiple>
                <v-expansion-panel v-for="(el, idx) in elementi" :key="idx">
                  <v-expansion-panel-header>{{ el.label || el.modulo }}</v-expansion-panel-header>
                  <v-expansion-panel-content v-for="(se, itmx) in el.settings" :key="itmx">
                    <v-text-field dense :label="se.label" v-model="se.value" :hint="se.hint" :persistent-hint="se.persistent" :required="se.required" :rules="se.rules"></v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>             
              </v-expansion-panels>
            </v-card-text>
          </template>          
          <v-card-actions>
            <v-btn>Scarica file configurazione</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="postazione.tipo === 'totem'" color="success" title="Se non presente, crea l'istanza del Totem che sarà accessibile da backoffice." @click="onCreaIstanza(postazione)">Crea istanza Totem</v-btn>
          </v-card-actions>
        </v-card>   
      </v-col>
    </v-row>
    <dialog-conferma
        :show="dialogConferma"
        :title="titoloConferma"
        :message="messaggioConferma"
        :onlyOk="onlyOk"
        :confirmText="confirmText"
        @dialog-confirm="onConferma"
        @dialog-cancel="dialogConferma = false"
      />    
  </v-container>
</template>

<script>
  import _ from 'lodash'
  import licenze from '@/services/licenzeService.js'
  import impostazioni from '@/services/impostazioniService'
  import configurazioni from '@/services/configurazioniRemoteService'
  import DialogConferma from '@/components/dialogConferma'
  import { rivenditore } from '@/mixins/rivenditore.js'
  import VJsonEditor from 'v-jsoneditor'

  const webpush = require('web-push')
  const impostazioniLocali = require('./prerequisiti/elementi.json')
  const hwdInfo = require('./prerequisiti/hwdInfo.json')  // TODO: Mettere dinamico

  export default {
    components: {
      DialogConferma,
      VJsonEditor
    },
    props: {
      cliente: Object
    },
    mixins: [
      rivenditore
    ],
    data(vm) {
      return {
        setup: null,
        tipiInstallazione: [],
        tipo: null,
        sceltaInstallazione: null,
        prerequisiti: null,
        changeDisabled: false,
        numeroPostazioni: 1,
        postazioni: [],
        titoloConferma: '',
        messaggioConferma: '',
        onlyOk: false,
        onConferma: vm.onRimuoviPostazioneConfirm,
        confirmText: 'SI',
        dialogConferma: false,
        dialogPayload: null,
        daSalvare: false,
        mostraImpostazioniLocali: {},
        triggerAggiorna: 1,
        elementi: [],
        rules: {
          required: val => (val || '').length > 0 || 'Il campo è richiesto',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          nospaces: value => (value || '').indexOf(' ') < 0 || 'Gli spazi non sono ammessi'
        },
        optionsWeb: {
          mode: 'form',
          modes: ['code', 'form'],
          search: true,
          enableSort: false,
          enableTransform: false
        },
        baseDb: '',
        istanzaDb: '',
        loading: false,
        licenzaTotem: false // NB: La licenza totem può essere presente anche insieme alle licenze standard, oltre che in modo isolato
      }
    },
    async mounted() {
      this.loading = true
      this.elementi = impostazioniLocali.filter(x => x.settings && x.settings.length > 0) // mettere su DB
      /*
      const elenco = await impostazioni.leggeAlberoImpostazioni()
      const setup = elenco.find(x => x._setup)
      this.setup = setup.dati
      */
      const setup = await impostazioni.getInfoSetup()
      this.setup = setup.dati
      let tipi = _.get(this.setup, 'sceltaInstallazione.tipiInstallazione', [])
      if (this.isTotem) {
        tipi = tipi.filter(x => x.codice === 'totem')
      }
      this.tipiInstallazione = _.sortBy(tipi, 'descrizione')
      this.prerequisiti = this.cliente.prerequisiti || {}
      
      if (this.prerequisiti.tipoInstallazione) {
        this.tipo = this.prerequisiti.tipoInstallazione
        // this.changeDisabled = true
      }
      this.postazioni = this.prerequisiti.postazioni || []
      // TODO: Verificare se aggiungere automaticamente una postazione. Tolto per gestione licenza mista cassa / totem
/*       if (this.postazioni.length === 0) {
        const postazione = await this.getPostazioneDefault(0)
        this.postazioni.push({ ...postazione })
      } */
      let baseDb = null
      if (this.cliente && this.cliente.licenze) {
        const licenza = this.cliente.licenze[0]
        this.licenza = licenza
        baseDb = licenza.baseDb
      } else {
        baseDb = this.cliente.baseDb
      }
      this.baseDb = baseDb
      this.istanzaDb = await this.getIstanzaDb(this.cliente.reseller)

      // NB: La licenza totem può essere presente anche insieme alle licenze standard, oltre che in modo isolato
      this.licenzaTotem = this.cliente && this.cliente.licenze[0].moduli.some(x => x.nome === 'totem')
      this.loading = false
    },
    beforeMount() {
      window.addEventListener("beforeunload", this.preventNav)
    },
    beforeDestroy() {
      window.removeEventListener("beforeunload", this.preventNav);
    },
    beforeRouteLeave (to, from, next) {
      if (this.modificheNonSalvate) {
        const answer = window.confirm('Ci sono delle modifiche non salvate, confermi uscita ?')
        if (!answer) return false
      }
      next()
    },    
    computed: {
      titolo() {
        return  _.get(this.setup, 'sceltaInstallazione.titolo', '')
      },
      descrizione() {
        return  _.get(this.setup, 'sceltaInstallazione.descrizione', '')
      },
      informazioni() {
        return  _.get(this.setup, 'sceltaInstallazione.informazioni', '')
      },
      modificheNonSalvate() {
        return this.daSalvare
      },
      hwdInfo() {
        return hwdInfo
      },
      isTotem() {
        return this.cliente && this.cliente.licenze[0].tipo === 'totem'
      }
    },
    methods: {
      liberaLicenza(postazione) {
        if (postazione.systemInfo) {
          this.titoloConferma = 'Eliminazione associazione licenza'
          this.messaggioConferma = `Confermi l'eliminazione della associazione della licenza alla postazione? <br/><br/>Il dispositivo associato non potrà più utilizzare il software <br/>fino ad nuova installazione!`
          this.onConferma = this.liberaLicenzaConfirm
          this.dialogConferma = true
          this.dialogPayload = postazione
        }
      },
      async liberaLicenzaConfirm() {
        try {
          const postazione = this.dialogPayload
          const result = await licenze.unmapLicenseKey(this.licenza.apikey, postazione.id, postazione.systemInfo)
          if (result && result.status === 'ok') {
            this.dialogPayload.systemInfo = ''
          }
        } catch(err) {
          console.lor(err)
        }
        this.dialogConferma = false
        this.daSalvare = true
      },

      impostaDefault(postazione) {
        if (postazione.soloCloud) {
          postazione.couchDbUser = ''
          postazione.couchDbPassword = ''
        } else {
          postazione.couchDbUser = postazione.couchDbUser || 'user'
          postazione.couchDbPassword = postazione.couchDbPassword || 'P@ssw0rd!'
        }
        this.daSalvare = true
      },
      mostraLocali(triggerAggiorna, idx) {
        return triggerAggiorna && !this.mostraImpostazioniLocali[idx]
      },
      async toggleLocali(idx) {
        this.mostraImpostazioniLocali[idx] = !this.mostraImpostazioniLocali[idx]
        await this.$nextTick()
        this.triggerAggiorna++
      },
      preventNav(event) {
        if (!this.modificheNonSalvate) return
        event.preventDefault()
        event.returnValue = ""
      },
      async onSalvaModifiche() {
        this.prerequisiti.postazioni = this.postazioni
        this.$emit('aggiorna', this.cliente.codice, this.prerequisiti)
        this.daSalvare = false
      },
      onChangeTipoInstallazione(value) {
        this.prerequisiti.tipoInstallazione = value
        this.daSalvare = true
      },
      onChangeTipoStampanteFiscale(postazione, idx) {
        const tipo = postazione.stampanti.fiscali[idx].tipo
        const impo = this.setup.moduli.stampanti.fiscali.find(x => x.tipo === tipo)
        postazione.stampanti.fiscali[idx].ip = impo.ip
        postazione.stampanti.fiscali[idx].portaServizio = impo.portaServizio
        postazione.stampanti.fiscali[idx].descrizione = impo.descrizione
        this.daSalvare = true
      },
      onChangeTipoStampanteNonFiscale(postazione, idx) {
        const tipo = postazione.stampanti.comande[idx].tipo
        const impo = this.setup.moduli.stampanti.comande.find(x => x.tipo === tipo)
        postazione.stampanti.comande[idx].ip = impo.ip
        postazione.stampanti.comande[idx].portaServizio = impo.portaServizio
        postazione.stampanti.comande[idx].descrizione = impo.descrizione
        this.daSalvare = true
      },
      aggiungiStampanteFiscale(postazione) {
        if (postazione.stampanti.fiscali.length < 3) {
          const st = this.getStampanteFiscaleDefault('epson')
          postazione.stampanti.fiscali.push({ ...st })
          this.daSalvare = true
        }
      },
      aggiungiStampanteNonFiscale(postazione) {
        if (postazione.stampanti.comande.length < 3) {
          const st = this.getStampanteNonFiscaleDefault('epson')
          postazione.stampanti.comande.push({ ...st })
          this.daSalvare = true
        }
      },      
      rimuoviStampanteFiscale(postazione, stampante) {
        const index = postazione.stampanti.fiscali.indexOf(stampante)
        if (index > -1) {
          postazione.stampanti.fiscali.splice(index, 1)
          this.daSalvare = true
        }
      },
      rimuoviStampanteNonFiscale(postazione, stampante) {
        const index = postazione.stampanti.comande.indexOf(stampante)
        if (index > -1) {
          postazione.stampanti.comande.splice(index, 1)
          this.daSalvare = true
        }
      },
      getStampanteFiscaleDefault(tipo) {
        const impo = this.setup.moduli.stampanti.fiscali.find(x => x.tipo === tipo)
        return impo
      },
      getStampanteNonFiscaleDefault(tipo) {
        const impo = this.setup.moduli.stampanti.comande.find(x => x.tipo === tipo)
        return impo
      },
      async getPostazioneDefault(idx, tipo='cassa') {
        const st = this.getStampanteFiscaleDefault('epson')
        const defPrefix = tipo === 'totem' ? 'Totem' : 'Cassa'
        const codice = await licenze.getShortApiKey(3)

        let def = {
          id: codice.apikey,
          nome: `${defPrefix} ${String(idx + 1).padStart(2, '0')}`,
          tipo: tipo,
          descrizione: '',
          soloCloud: false,
          couchDbUser: 'user',
          couchDbPassword: 'P@ssw0rd!', 
          stampanti: {
            fiscali: [],
            comande: []
          }
        }
        def.stampanti.fiscali.push({ ...st })
        this.daSalvare = true
        return def
      },
      async onCreaIstanza(postazione) {
        const nomeDb = `${this.baseDb}_configurazioni`
        let objTotem = await configurazioni.leggeConfigurazione(nomeDb, { _id: 'totem' }, this.istanzaDb)
        if (!objTotem) {
          alert('Le configurazioni non sono ancora state create. Operazione non possibile !')
          return
        }
        objTotem.totem = objTotem.totem || []
        let totem = objTotem.totem.find(x => x.codice === postazione.id)
        if (!totem) {
          totem = {
            codice: postazione.id,
            descrizione: postazione.descrizione,
            stato: null,
            impostazioni: objTotem.impostazioni,
            stampantiComande: objTotem.stampantiComande,
            alberaturaAssets: objTotem.alberaturaAssets,
            struttura: objTotem.struttura,
            tema: objTotem.tema,
            configurazione: objTotem.configurazione,
            lingue: objTotem.lingue,
            categorie: []
          }
          objTotem.totem.push(totem) 
        } else {
          totem.descrizione = postazione.descrizione
        }
        await configurazioni.modificaConfigurazione(nomeDb, objTotem, this.istanzaDb)
        alert('Istanza Totem aggiornata con successo !')
      },
      async onNuovaPostazione() {
        const postazione = await this.getPostazioneDefault(this.postazioni.length)
        this.postazioni.push({ ...postazione })
        this.daSalvare = true
      },
      async onNuovoTotem() {
        const postazione = await this.getPostazioneDefault(this.postazioni.length, 'totem')
        this.postazioni.push({ ...postazione })
        this.daSalvare = true
      },
      onRimuoviPostazione(postazione) {
        this.titoloConferma = 'Cancellazione postazione'
        this.messaggioConferma = `Confermi cancellazione Postazione: ${postazione.nome}?`
        this.onConferma = this.onRimuoviPostazioneConfirm
        this.dialogConferma = true
        this.dialogPayload = postazione
        this.daSalvare = true
      },
      async onRimuoviPostazioneConfirm() {
        this.dialogConferma = false
        const postazione = this.dialogPayload
        const rimuoviTotem = postazione.tipo === 'totem'
        const index = this.postazioni.indexOf(postazione)
        if (index > -1) {
          this.postazioni.splice(index, 1)
          this.daSalvare = true
          if (rimuoviTotem) {
            await this.verificaCancellazioneTotem(postazione)
          }
        }
      },
      async verificaCancellazioneTotem(postazione) {
        const nomeDb = `${this.baseDb}_configurazioni`
        let objTotem = await configurazioni.leggeConfigurazione(nomeDb, { _id: 'totem' }, this.istanzaDb)
        if (!objTotem) {
          return
        }
        objTotem.totem = objTotem.totem || []
        let totem = objTotem.totem.find(x => x.codice === postazione.id)
        if (totem) {
          const index = objTotem.totem.indexOf(totem)
          objTotem.totem.splice(index, 1)
        }
        await configurazioni.modificaConfigurazione(nomeDb, objTotem, this.istanzaDb)
      },
      async generaVapidKeys(idx) {
        const vapidKeys = webpush.generateVAPIDKeys()
        this.postazioni[idx].publicVapidKey = vapidKeys.publicKey
        this.postazioni[idx].privateVapidKey = vapidKeys.privateKey
        await this.$nextTick()
        this.$forceUpdate()
      }
    }
  }
</script>

<style scoped>
  .btn-da-salvare {
    position: absolute;
    left: auto;
    right: 10px;
  }
</style>